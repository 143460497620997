import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { City } from 'app/feature/bus/busInterfaces';
import ClearDataButton from './ClearDataButton';

export interface CityInputProps {
  onChange?: (value: string) => void;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  city?: City[];
  originInputValue?: string;
}

const CityInput: FC<CityInputProps> = ({
  autoFocus = false,
  onChange,
  onInputDone,
  placeHolder = 'Location',
  desc = 'Where are you going?',
  className = 'nc-flex-1.5',
  city,
  originInputValue,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(originInputValue || '');
  const [suggestions, setSuggestions] = useState<City[] | undefined>([]);
  const [showPopover, setShowPopover] = useState(autoFocus);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener('click', eventClickOutsideDiv);
    }
    showPopover && document.addEventListener('click', eventClickOutsideDiv);
    return () => {
      document.removeEventListener('click', eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  let matchedArray: City[] | undefined = [];

  const onChangeHandler = (text: string) => {
    if (text.length > 0) {
      matchedArray = city?.filter((item) => {
        const regex = new RegExp(`${text}`, 'gi');
        return item.locName.match(regex);
      });
    }
    setSuggestions(matchedArray);
    setValue(text);
  };

  const handleSelectLocation = (item: string) => {
    matchedArray = city?.filter((loc) => {
      const regex = new RegExp(`${item}`, 'gi');
      return loc.locName.match(regex);
    });
    setSuggestions(matchedArray);
    setValue(item);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  const renderPopularSearches = () => {
    return (
      <Fragment>
        <h3 className='block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100'>
          Popular searches
        </h3>
        <div className='mt-2'>
          {city?.map((item) => (
            <span
              onClick={() => handleSelectLocation(item.locName)}
              key={item._id}
              className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'
            >
              <span className='block text-neutral-400'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-4 sm:h-6 w-4 sm:w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
                  />
                </svg>
              </span>
              <span className=' block font-medium text-neutral-700 dark:text-neutral-200'>
                {item.locName}
              </span>
            </span>
          ))}
        </div>
      </Fragment>
    );
  };

  const renderSearchValue = () => {
    return (
      <Fragment>
        {suggestions?.length !== 0 ? (
          suggestions?.map((item) => (
            <span
              onClick={() => handleSelectLocation(item.locName)}
              key={item._id}
              className='flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer'
            >
              <span className='block text-neutral-400'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-4 w-4 sm:h-6 sm:w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
                  />
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
                  />
                </svg>
              </span>
              <span className='block font-medium text-neutral-700 dark:text-neutral-200'>
                {item.locName}
              </span>
            </span>
          ))
        ) : (
          <span className='block font-medium text-neutral-700 dark:text-neutral-200 text-center'>
            No Result Found
          </span>
        )}
      </Fragment>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
          }`}
      >
        <div className='text-neutral-300 dark:text-neutral-400'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='nc-icon-field'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
            />
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1.5}
              d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
            />
          </svg>
        </div>
        <div className='flex-grow'>
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => onChangeHandler(e.currentTarget.value)}
            ref={inputRef}
          />
          <span className='block mt-0.5 text-sm text-neutral-400 font-light '>
            <span className='line-clamp-1'>{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton onClick={() => setValue('')} />
          )}
        </div>
      </div>
      {showPopover && (
        <div className='absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto'>
          {value ? renderSearchValue() : renderPopularSearches()}
        </div>
      )}
    </div>
  );
};

export default CityInput;
