import axios from 'axios';

const URL = process.env.REACT_APP_SERVER_URL_TOUR;

export const fetchTourCities = async () => {
  const response = await axios.get(
    `${URL}api/v1/search-items?fields=cityName,propertyId,cityId&variant=city`
  );
  return response.data;
};

export const fetchAllTour = async (propertyId?: string) => {
  try {
    let response: any;
    if (typeof propertyId !== 'undefined') {
      response = await axios.get(
        `${URL}api/v1/tour-list${propertyId && `?propertyCode=${propertyId}`}`
      );
    } else {
      response = await axios.get(`${URL}api/v1/tour-list`);
    }
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchOneTour = async (id: string) => {
  try {
    const response = await axios.get(`${URL}api/v1/tour-list/${id}`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchPaymentTour = async (body: any, token: string) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.post(
      `${URL}api/v1/bookings/checkout-sslcommerz`,
      body,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchVat = async () => {
  try {
    const response = await axios.get(`${URL}api/v1/vat-service-charge`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};
