import { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router';
import HotelLocationInput from './HotelLocationInputForm';
import { FocusedInputShape } from 'react-dates';
import HotelDatesRangeInput from './HotelDatesRangeInput';
import moment from 'moment';
import {
  fetchHotelLocationAsync,
  addUserInput,
  fetchAllHotelAsync,
} from 'app/feature/hotel/hotelSlice';
import { useAppDispatch, useAppSelector } from 'app/hook';
import { HotelUserInput, Location } from 'app/feature/hotel/hotelInterfaces';
import ButtonSubmit from './ButtonSubmit';

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

const HotelSearchForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch<any>(fetchHotelLocationAsync());
  }, []);

  const { locations, hotelUserInput } = useAppSelector((state) => state.hotel);

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: hotelUserInput?.checkIn ? moment(hotelUserInput.checkIn) : null,
    endDate: hotelUserInput?.checkOut ? moment(hotelUserInput.checkOut) : null,
  });

  const [locationInputValue, setLocationInputValue] = useState<string>(
    hotelUserInput?.location.cityName || ''
  );

  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );

  const [userInput, setUserInput] = useState<HotelUserInput>();

  const formatFormData = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    locations.forEach(
      (loc: Location) =>
        loc.cityName === locationInputValue &&
        setUserInput({
          location: loc,
          checkIn: dateRangeValue.startDate?.toISOString(),
          checkOut: dateRangeValue.endDate?.toISOString(),
        })
    );

    console.log(locations);
  };

  useEffect(() => {
    if (userInput?.location && userInput.checkIn && userInput.checkOut) {
      dispatch(addUserInput(userInput));
      dispatch<any>(
        fetchAllHotelAsync({
          id: userInput.location.propertyId,
          checkIn: userInput.checkIn,
          checkOut: userInput.checkOut,
          page: 1,
          limit: 8,
        })
      );
      history.push('/hotel');
    }
  }, [userInput]);

  return (
    <form
      onSubmit={(ev) => formatFormData(ev)}
      className='w-full relative mt-8 flex flex-col md:flex-row md:items-center rounded-3xl lg:rounded-full shadow-2xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 md:divide-y-0'
    >
      <HotelLocationInput
        locationInputValue={locationInputValue}
        locations={locations}
        onChange={(ev) => setLocationInputValue(ev)}
        onInputDone={() => setDateFocused('startDate')}
      />

      <HotelDatesRangeInput
        defaultValue={dateRangeValue}
        defaultFocus={dateFocused}
        onFocusChange={(focus) => setDateFocused(focus)}
        onChange={(data) => setDateRangeValue(data)}
      />

      <div className=' px-4 py-4 lg:py-0'>
        <ButtonSubmit />
      </div>
    </form>
  );
};

export default HotelSearchForm;
