import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchHotelLocation,
  fetchAllHotelList,
  fetchSingleHotel,
  fetchVat,
} from './hotelApi';

import {
  HotelState,
  LocationApiCall,
  VatAndServiceApiCall,
  HotelListApiCall,
  UserChooserData,
  OneHoteltApiCall,
} from './hotelInterfaces';

const initialState: HotelState = {
  status: 'idle',
  locations: [],
  allHotelList: [],
};

export const fetchHotelLocationAsync = createAsyncThunk(
  'hotel/fetchLocation',
  async () => {
    const { data }: LocationApiCall = await fetchHotelLocation();
    return data;
  }
);

export const fetchAllHotelAsync = createAsyncThunk(
  'hotel/fetchAllHotel',
  async (userChosenData: UserChooserData) => {
    const { id, checkIn, checkOut, page, limit, order, priceRange, rating } =
      userChosenData;
    const { data }: HotelListApiCall = await fetchAllHotelList(
      id,
      order,
      checkIn,
      checkOut,
      page,
      limit,
      priceRange,
      rating
    );

    return data;
  }
);

export const fetchSingleHotelAsync = createAsyncThunk(
  'hotel/fetchSingleHotel',
  async (userChosenData: UserChooserData) => {
    const { id, checkIn, checkOut } = userChosenData;
    const { data }: OneHoteltApiCall = await fetchSingleHotel(
      id,
      checkIn,
      checkOut
    );
    return data;
  }
);

export const fetchVatAsync = createAsyncThunk('hotel/fetchVat', async () => {
  const { data }: VatAndServiceApiCall = await fetchVat();
  return data;
});

export const hotelSlice = createSlice({
  name: 'hotel',
  initialState,
  reducers: {
    addUserInput: (state, action) => {
      state.hotelUserInput = action.payload;
    },
    addFinalInput: (state, action) => {
      state.hotelFinalInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelLocationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHotelLocationAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.locations = action.payload;
      })
      .addCase(fetchHotelLocationAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAllHotelAsync.pending, (state) => {
        state.status = 'loading';
      })

      // Fix Me
      .addCase(fetchAllHotelAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.allHotelList = action.payload.hotels;
        state.filters = action.payload.filter;
        state.totalPage = action.payload.pagination?.totalPages;
      })
      .addCase(fetchAllHotelAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSingleHotelAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleHotelAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.oneHotel = action.payload;
      })
      .addCase(fetchSingleHotelAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchVatAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVatAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.vatAndServices = action.payload;
      })
      .addCase(fetchVatAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addUserInput, addFinalInput } = hotelSlice.actions;

export default hotelSlice.reducer;
