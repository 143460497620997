import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import TourSearchForm from './TourSearchForm';
import RentalCarSearchForm from './RentalCarSearchForm';
import HotelSearchForm from './HotelSearchForm';
import BusSearchForm from './BusSearchForm';
import LaunchSearchForm from './LaunchSearchForm';
import FlightSearchForm from './FlightSearchForm';
import TrainSearchForm from './TrainSearchForm';

export type SearchTab =
  | 'Hotel'
  | 'Bus'
  | 'Launch'
  | 'Package Tour'
  | 'Rent A Car'
  | 'Flight'
  | 'Train';

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?:
    | 'Hotel'
    | 'Bus'
    | 'Launch'
    | 'Package Tour'
    | 'Rent A Car'
    | 'Flight'
    | 'Train';
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = '',
  currentTab = 'Hotel',
  currentPage,
}) => {
  const tabs: SearchTab[] = [
    'Hotel',
    'Bus',
    'Launch',
    'Package Tour',
    'Rent A Car',
    'Flight',
    'Train',
  ];
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const renderTab = () => {
    return (
      <ul className='flex justify-center flex-wrap space-x-8 md:space-x-7 lg:space-x-10 xl:space-x-12'>
        {tabs.map((tab) => {
          const active = tab === tabActive;
          return (
            <Link
              key={tab}
              to={
                tab === 'Flight' || tab === 'Train'
                  ? '/'
                  : `${tab}`.replace(/\s+/g, '-').toLowerCase()
              }
            >
              <li
                onClick={() => setTabActive(tab)}
                className={`flex items-center cursor-pointer text-sm lg:text-base font-medium ${
                  active
                    ? ''
                    : 'text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-100'
                } `}
              >
                {active && (
                  <span className='block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-neutral-100 mr-2' />
                )}
                <span>{tab}</span>
              </li>
            </Link>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (tabActive) {
      case 'Hotel':
        return <HotelSearchForm />;
      case 'Bus':
        return <BusSearchForm />;
      case 'Launch':
        return <LaunchSearchForm />;
      case 'Package Tour':
        return <TourSearchForm />;
      case 'Rent A Car':
        return <RentalCarSearchForm />;
      case 'Flight':
        return <FlightSearchForm />;
      case 'Train':
        return <TrainSearchForm />;

      default:
        return null;
    }
  };

  return (
    <div
      className={` w-full max-w-6xl py-5 lg:py-0 ${className}`}
      data-nc-id='HeroSearchForm'
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
