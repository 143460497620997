import { FC, Fragment, useEffect, useState, useMemo } from 'react';
import CardHotel from 'components/CardHotel/CardHotel';
import Heading2 from 'components/Heading/Heading2';
import { useAppDispatch, useAppSelector } from 'app/hook';
import HotelFilters from './HotelFilters';
import PageLoading from 'containers/PageLoading/PageLoading';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { fetchAllHotelAsync } from 'app/feature/hotel/hotelSlice';
import NcImage from 'shared/NcImage/NcImage';
import HotelNotFound from 'images/HotelNotFound.svg';
import { OrderTypes, Popular } from 'app/feature/hotel/hotelInterfaces';
import { fetchPopularHotel } from 'app/feature/hotel/hotelApi';
export interface SectionGridFilterCardProps {
  className?: string;
  heading?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
  heading,
}) => {
  const { hotelUserInput, allHotelList, status, totalPage, filters } =
    useAppSelector((state) => state.hotel);
  const dispatch = useAppDispatch();

  let limit = 8;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState<OrderTypes>('default');
  const [priceRange, setPriceRange] = useState<number[]>();
  const [rating, setRating] = useState<string>();

  useEffect(() => {
    setLoading(true);
    if (hotelUserInput && hotelUserInput.checkIn && hotelUserInput.checkOut) {
      dispatch<any>(
        fetchAllHotelAsync({
          id: hotelUserInput.location.propertyId,
          checkIn: hotelUserInput.checkIn,
          checkOut: hotelUserInput.checkOut,
          page: page,
          limit: limit,
          order: orderType,
          priceRange: priceRange,
          rating: rating,
        })
      );
    }
    setLoading(false);
  }, [page, orderType, priceRange, rating]);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id='SectionGridFilterCard'
    >
      <Heading2 heading={heading} />

      {filters && (
        <div className='mb-8 lg:mb-11'>
          <HotelFilters
            orderTypeState={orderType}
            onOrderChange={(value) => setOrderType(value)}
            minPrice={filters.price.minPrice}
            maxPrice={filters.price.maxPrice}
            onPriceRangeChange={(value) => setPriceRange(value)}
            onRatingChange={(value) => setRating(value)}
          />
        </div>
      )}

      {status === 'loading' ? (
        <PageLoading />
      ) : allHotelList?.length === 0 ? (
        <div className='max-w-2xl mx-auto my-12'>
          <NcImage src={HotelNotFound} />
          <span className='block text-xl text-neutral-800 dark:text-neutral-200 tracking-wider font-medium text-center mt-6'>
            NO HOTEL FOUND.
          </span>
        </div>
      ) : (
        <Fragment>
          <div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
            {allHotelList?.map((hotel) => (
              <CardHotel key={hotel._id} data={hotel} />
            ))}
          </div>

          <div className='flex mt-16 justify-center items-center gap-5'>
            <ButtonPrimary
              loading={loading}
              onClick={() => setPage(page - 1)}
              disabled={page === 1}
            >
              <i className='las la-angle-left mr-2  text-lg' /> Prev Page
            </ButtonPrimary>

            <ButtonPrimary
              loading={loading}
              disabled={page === totalPage || totalPage === undefined}
              onClick={() => setPage(page + 1)}
            >
              Next Page <i className='las la-angle-right ml-2 text-lg' />{' '}
            </ButtonPrimary>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SectionGridFilterCard;
