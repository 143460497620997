import { FC, Fragment, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useHistory } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
// import SectionSliderNewCategories from 'components/SectionSliderNewCategories/SectionSliderNewCategories';
import ModalPhotos from 'components/ModalPhotos/ModalPhotos';
import { useAppSelector, useAppDispatch } from 'app/hook';
import {
  addFinalInput,
  fetchOneTourAsync,
  fetchVatAsync,
} from 'app/feature/tour/tourSlice';
import PageLoading from 'containers/PageLoading/PageLoading';
import SomethingWrong from 'containers/PageSomethingWrong/PageSomethingWrong';
import Page404 from 'containers/Page404/Page404';
import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import ClearDataButton from 'components/HeroSearchForm/ClearDataButton';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';

export interface PageTourDetailsProps {
  match?: any;
}

const PageTourDetails: FC<PageTourDetailsProps> = ({ match }) => {
  const { status, oneTour, vatAndServices } = useAppSelector(
    (state) => state.tour
  );
  const { isLogged } = useAppSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [guests, setGuests] = useState(1);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const { id } = match.params;

  useEffect(() => {
    dispatch<any>(fetchVatAsync());
    dispatch<any>(fetchOneTourAsync(id));
  }, [id]);

  let vatPercentige = 0;
  let serviceCharge = 0;
  let amount = 0;

  if (oneTour) {
    amount = guests * oneTour.price;
  }

  vatAndServices?.map((item) =>
    item.variant === 'vat'
      ? (vatPercentige = item.chargeAmount)
      : (serviceCharge = item.chargeAmount)
  );

  const reserveBtnClick = () => {
    console.log(serviceCharge);
    dispatch(
      addFinalInput({
        tour: oneTour,
        vatPercentige: vatPercentige,
        serviceCharge: serviceCharge,
        amount: amount,
        adults: guests,
        children: 0,
      })
    );

    if (guests > 0) {
      if (isLogged) {
        history.push('/package-tour/checkout');
      } else {
        history.push('/login');
      }
    }
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      oneTour && (
        <div className='listingSection__wrap !space-y-6'>
          {/* 1 */}
          <h2 className='text-2xl sm:text-3xl lg:text-4xl font-semibold'>
            {oneTour.destination}
          </h2>

          {/* 2 */}
          <div className='flex items-center space-x-4'>
            <i className='las la-map-marker-alt mr-2' />{' '}
            {oneTour.location.cityName}, {oneTour.location.countryName}
          </div>
        </div>
      )
    );
  };

  const renderSection2 = () => {
    return (
      oneTour && (
        <div className='listingSection__wrap'>
          <h2 className='text-2xl font-semibold'>Tour descriptions</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='text-neutral-6000 dark:text-neutral-300'>
            <p>{oneTour.summary}</p>
          </div>
        </div>
      )
    );
  };

  const renderSection3 = () => {
    return (
      oneTour && (
        <div className='listingSection__wrap'>
          <div>
            <h2 className='text-2xl font-semibold'>Facilities </h2>
            <span className='block mt-2 text-neutral-500 dark:text-neutral-400'>
              Included in the price
            </span>
          </div>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          {/* 6 */}
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 '>
            {oneTour.facilities.map((item, index) => (
              <div key={index} className='flex items-center space-x-3'>
                <i className='las la-check-circle text-2xl'></i>
                <span>{item}</span>
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const renderSidebar = () => {
    return (
      oneTour && (
        <div className='listingSection__wrap shadow-xl'>
          {/* PRICE */}
          <div className='flex justify-between items-center'>
            <span className='text-2xl font-semibold'>
              BDT {oneTour.price}
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /person
              </span>
            </span>
            <div className='flex align-middle'>
              <i className='las la-clock text-2xl mr-2' />
              {oneTour.numOfDays} days & {oneTour.numOfNights} nights{' '}
            </div>
          </div>

          {/* FORM */}
          <form className='flex border divide-x divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl '>
            <div className='flex-1'>
              <Popover className='flex relative [ nc-flex-1 ]'>
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={`flex text-left w-full flex-shrink-0 items-center p-5 space-x-3 focus:outline-none cursor-pointer ${
                        open
                          ? 'shadow-2xl rounded-full dark:bg-neutral-800'
                          : ''
                      }`}
                    >
                      <div className='text-neutral-300 dark:text-neutral-400'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='nc-icon-field'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={1.5}
                            d='M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z'
                          />
                        </svg>
                      </div>
                      <div className='flex-grow'>
                        <span className='block xl:text-lg font-semibold'>
                          {guests} Guests
                        </span>
                        <span className='block mt-1 text-sm text-neutral-400 leading-none font-light'>
                          {guests > 0 ? 'Guests' : 'Add guests'}
                        </span>
                        {!!guests && open && (
                          <ClearDataButton
                            onClick={() => {
                              setGuests(1);
                            }}
                          />
                        )}
                      </div>
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <Popover.Panel className='absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl'>
                        <NcInputNumber
                          className='w-full'
                          defaultValue={guests}
                          onChange={(value) => setGuests(value)}
                          max={10}
                          min={1}
                          label='Person'
                        />
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </form>

          {/* SUM */}
          <div className='flex flex-col space-y-4'>
            <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
              <span>
                BDT {oneTour.price} x {guests} guests
              </span>
              <span>BDT {amount}</span>
            </div>
            <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
              <span>Service charge</span>
              <span>BDT {serviceCharge}</span>
            </div>
            <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
            <div className='flex justify-between font-semibold'>
              <span>Total</span>
              <span>BDT {serviceCharge + amount}</span>
            </div>
          </div>

          {/* SUBMIT */}
          <ButtonPrimary onClick={reserveBtnClick}>Reserve</ButtonPrimary>
        </div>
      )
    );
  };

  return status === 'loading' ? (
    <PageLoading />
  ) : status === 'idle' ? (
    oneTour ? (
      <div
        className={`nc-ListingExperiencesDetailPage`}
        data-nc-id='ListingExperiencesDetailPage'
      >
        {/* SINGLE HEADER */}

        <header className='container 2xl:px-14 rounded-md sm:rounded-xl'>
          <div className='relative grid grid-cols-4 gap-1 sm:gap-2'>
            <div
              className='col-span-3 row-span-3 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer'
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName='absolute inset-0'
                className='object-cover w-full h-full rounded-md sm:rounded-xl'
                src={oneTour.images[0]}
                prevImageHorizontal
              />
              <div className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity'></div>
            </div>
            {oneTour.images
              .filter((_, i) => i >= 1 && i < 4)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 2 ? 'block' : ''
                  }`}
                >
                  <NcImage
                    containerClassName='aspect-w-4 aspect-h-3'
                    className='object-cover w-full h-full rounded-md sm:rounded-xl '
                    src={item || ''}
                    prevImageHorizontal
                  />

                  {/* OVERLAY */}
                  <div
                    className='absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer'
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

            <div
              className='absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10'
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={1.5}
                  d='M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z'
                />
              </svg>
              <span className='ml-2 text-neutral-800 text-sm font-medium'>
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={oneTour.images}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />

        {/* MAIn */}
        <main className='container mt-11 flex '>
          {/* CONTENT */}
          <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10'>
            {renderSection1()}
            {renderSection2()}
            {renderSection3()}
          </div>

          {/* SIDEBAR */}
          <div className='hidden lg:block flex-grow'>
            <div className='sticky top-24'>{renderSidebar()}</div>
          </div>
        </main>

        {/* STICKY FOOTER MOBILE */}
        <div className='block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20'>
          <div className='container flex items-center justify-between'>
            <span className='text-2xl font-semibold'>
              $311
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /person
              </span>
            </span>

            <ButtonPrimary href='##'>Reserve</ButtonPrimary>
          </div>
        </div>

        {/* OTHER SECTION */}
        <div className='container py-24 lg:py-32'>
          {/* SECTION 1 */}
          {/* <div className='relative py-16'>
            <BackgroundSection />
            <SectionSliderNewCategories
              heading='Explore by types of stays'
              subHeading='Explore houses based on 10 types of stays'
              categoryCardType='card5'
              itemPerRow={5}
              sliderStyle='style2'
            />
          </div> */}

          {/* SECTION */}
          <SectionSubscribe2 className='pt-24 lg:pt-32' />
        </div>
      </div>
    ) : (
      <SomethingWrong />
    )
  ) : (
    <Page404 />
  );
};

export default PageTourDetails;
