import axios from 'axios';

const URL = process.env.REACT_APP_SERVER_URL_CAR;

export const fetchCarCities = async () => {
  const response = await axios.get(
    `${URL}api/v1/cities/client?fields=cityName,cityId,regions`
  );
  return response.data;
};

export const fetchCarList = async (
  fromCity: string,
  fromRegion: string,
  depDate: string,
  retDate: string
) => {
  try {
    const response = await axios.get(
      `${URL}api/v1/car-list?fromCity=${fromCity}&fromRegion=${fromRegion}&depDate=${depDate}&retDate=${retDate}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchSingleCar = async (id: string) => {
  try {
    const response = await axios.get(`${URL}api/v1/car-list/${id}`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchPaymentCar = async (body: any, token: string) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.post(
      `${URL}api/v1/bookings/checkout-sslcommerz`,
      body,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchVat = async () => {
  try {
    const response = await axios.get(`${URL}api/v1/vat-service-charge`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};
