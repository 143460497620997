import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { PencilAltIcon } from '@heroicons/react/outline';
import Input from 'shared/Input/Input';
import Label from 'components/Label/Label';
import Textarea from 'shared/Textarea/Textarea';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import NcImage from 'shared/NcImage/NcImage';
import NcModal from 'shared/NcModal/NcModal';
import { useAppSelector, useAppDispatch } from 'app/hook';
import { fetchVatAsync } from 'app/feature/car/carSlice';
import { fetchPaymentCar } from 'app/feature/car/carApi';
import SomethingWrong from 'containers/PageSomethingWrong/PageSomethingWrong';
import PageLoading from 'containers/PageLoading/PageLoading';
import validateEmail from 'utils/validateEmail';

const PageCarCheckOut = () => {
  const dispatch = useAppDispatch();
  const { carFinalInput, carUserInput, status, vatAndServices } =
    useAppSelector((state) => state.car);
  const { userDetails, token } = useAppSelector((state) => state.user);

  const [name, setName] = useState(userDetails.name || '');
  const [email, setEmail] = useState(userDetails.email || '');
  const [phone, setPhone] = useState(userDetails.phone) || '';
  const [boardingPoint, setBoardingPoint] = useState('');
  const [droppingPoint, setDroppingPoint] = useState('');
  const [message, setMessage] = useState('');
  const [check, setCheck] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch<any>(fetchVatAsync());
  }, []);

  let vat = 0;
  let serviceCharge = 0;
  let vatPercentige = 0;
  let amount = 0;
  let totalAmount = 0;

  vatAndServices?.map((item) =>
    item.variant === 'vat'
      ? (vatPercentige = item.chargeAmount)
      : (serviceCharge = item.chargeAmount)
  );

  if (carFinalInput) {
    amount = carFinalInput.amount;
    vat = Math.ceil(amount * (vatPercentige / 100));
    totalAmount = amount + vat + serviceCharge;
  }

  const handlePaymentSubmit = () => {
    if (name.length === 0) {
      setError('Name is required');
    } else if (email.lenght === 0) {
      setError('Email is required');
    } else if (phone.length === 0) {
      setError('Phone number is required');
    } else if (boardingPoint.length === 0) {
      setError('Boarding Point is required');
    } else if (droppingPoint.length === 0) {
      setError('Dropping Point is required');
    } else if (!validateEmail(email)) {
      setError('Email is not validate');
    } else {
      const dataForBody = {
        car: carFinalInput?.car._id,
        cusName: name,
        cusEmail: email,
        cusPhone: phone,
        amount: amount,
        totalAmount: totalAmount,
        boardingPoint: boardingPoint,
        droppingPoint: droppingPoint,
        depTime: moment(carFinalInput?.depTime, ['HH:mm a']).toISOString(),
        depDate: carFinalInput?.depDate,
        retDate: carFinalInput?.retDate,
        medium: 'web',
      };

      getData(dataForBody);
    }
  };

  const getData = async (body: any) => {
    setLoading(true);
    const carSSL: any = await fetchPaymentCar(body, token);
    setLoading(false);

    if (carSSL.status === 'success') {
      window.location.replace(carSSL.data);
    } else {
      setError(carSSL);
    }
  };

  const renderSidebar = () => {
    return (
      carFinalInput && (
        <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8 sticky'>
          <span className='text-2xl text-center'>
            {carFinalInput.car.agentName}
          </span>
          <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className='flex flex-col sm:flex-row sm:items-center'>
            <div className='flex-shrink-0 w-full sm:w-40'>
              <div className=' aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden'>
                <NcImage src={carFinalInput.car.images[0]} />
              </div>
            </div>
            <div className='py-5 sm:px-5 space-y-3'>
              <div>
                <span className='text-base font-medium mt-1 block'>
                  {carFinalInput.car.vehicleName}, {carFinalInput.car.model}
                </span>
                <span className='text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1'>
                  <i className='las la-map-marker-alt' />
                  <span className='ml-1'>
                    {carUserInput?.fromRegion},{' '}
                    {carUserInput?.fromCity.cityName}
                  </span>
                </span>
              </div>
              <span className='block  text-sm text-neutral-500 dark:text-neutral-400'>
                {carFinalInput.car.passengerCapacity} Seats
              </span>
            </div>
          </div>

          <div className='flex flex-col space-y-4'>
            <h3 className='text-2xl font-semibold'>Price detail</h3>
            <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
              <span>
                {carFinalInput?.car.dailyRent} x {carFinalInput?.day} days
              </span>
              <span>BDT {carFinalInput.car.dailyRent * carFinalInput.day}</span>
            </div>
            <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
              <span>VAT({vatPercentige}%)</span>
              <span>BDT {vat}</span>
            </div>
            <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
              <span>Service charge</span>
              <span>BDT {serviceCharge}</span>
            </div>

            <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
            <div className='flex justify-between font-semibold'>
              <span>Total Amount</span>
              <span>BDT {totalAmount}</span>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderMain = () => {
    return (
      <div className='w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8'>
        <h2 className='text-3xl lg:text-4xl font-semibold'>
          Confirm and payment
        </h2>
        <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
        <div>
          <div>
            <h3 className='text-2xl font-semibold'>Your trip</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className='block lg:hidden underline  mt-1 cursor-pointer'
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
            />
          </div>
          <div className='mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700'>
            <div className='flex-1 p-5 flex justify-between space-x-5'>
              <div className='flex flex-col'>
                <span className='text-sm text-neutral-400'>Date</span>
                <span className='mt-1.5 text-lg font-semibold'>
                  {moment(carFinalInput?.depDate).utc().format('MMM DD')} -{' '}
                  {moment(carFinalInput?.retDate).utc().format('MMM DD, YYYY')}
                </span>
              </div>
              <PencilAltIcon className='w-6 h-6 text-neutral-300 dark:text-neutral-6000' />
            </div>
            <div className='flex-1 p-5 flex justify-between space-x-5'>
              <div className='flex flex-col'>
                <span className='text-sm text-neutral-400'>Total Seats</span>
                <span className='mt-1.5 text-lg font-semibold'>
                  {carFinalInput?.car.passengerCapacity}
                </span>
              </div>
              <PencilAltIcon className='w-6 h-6 text-neutral-300 dark:text-neutral-6000' />
            </div>
          </div>
        </div>

        <div>
          <h3 className='text-2xl font-semibold'>User Details</h3>
          <div className='mt-6'>
            <div className='w-14 border-b border-neutral-200 my-5'></div>

            <div className='space-y-2'>
              <Label>Name</Label>
              <Input
                placeholder='Your Name'
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </div>
            <div className='space-y-2'>
              <Label>Email </Label>
              <Input
                type='email'
                placeholder='example@gmail.com'
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
              />
            </div>
            <div className='space-y-2'>
              <Label>Phone Number </Label>
              <Input
                placeholder='01*********'
                value={phone}
                onChange={(ev) => setPhone(ev.target.value)}
              />
            </div>
            <div className='space-y-2'>
              <Label>Pickup Address </Label>
              <Textarea
                rows={1}
                placeholder='...'
                value={boardingPoint}
                onChange={(ev) => setBoardingPoint(ev.target.value)}
              />
            </div>
            <div className='space-y-2'>
              <Label>Destination Address </Label>
              <Textarea
                rows={1}
                placeholder='...'
                value={droppingPoint}
                onChange={(ev) => setDroppingPoint(ev.target.value)}
              />
            </div>
            <div className='space-y-2'>
              <Label>Special Note </Label>
              <Textarea
                rows={1}
                placeholder='...'
                value={message}
                onChange={(ev) => setMessage(ev.target.value)}
              />
            </div>
            <div className='space-y-2'>
              <input
                type='checkbox'
                className='form-checkbox'
                checked={check}
                onChange={() => setCheck(!check)}
              />
              <span className='ml-2'>
                I've read{' '}
                <Link to='/terms-and-conditions'>
                  <span className='underline'>terms & conditions</span>
                </Link>
              </span>
            </div>
            <div className='flex justify-between py-4'>
              <div className='font-medium border-2 py-3 px-6 bg-white border-primary-6000 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-primary-6000'>
                <span className='font-semibold'>
                  Total Amount: BDT {totalAmount}
                </span>
              </div>

              <ButtonPrimary onClick={handlePaymentSubmit} loading={loading}>
                Pay with SSLCommerz
              </ButtonPrimary>
            </div>

            {error && (
              <span className='text-red-800 dark:text-red-400'>{error}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return status === 'loading' ? (
    <PageLoading />
  ) : (
    <div className={`nc-CheckOutPage`} data-nc-id='CheckOutPage'>
      {carFinalInput ? (
        <main className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'>
          <div className='w-full lg:w-3/5 xl:w-2/3 lg:pr-10 '>
            {renderMain()}
          </div>
          <div className='hidden lg:block flex-grow'>{renderSidebar()}</div>
        </main>
      ) : (
        <SomethingWrong />
      )}
    </div>
  );
};

export default PageCarCheckOut;
