import { FC, Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import NcInputNumber from 'components/NcInputNumber/NcInputNumber';
import ClearDataButton from './ClearDataButton';

export interface GuestsInputProps {
  defaultValue: {
    guestAdults: number;
    guestChildren: number;
    guestRoom: number;
  };
  maxAdult?: number;
  maxChildren?: number;
  maxRoom?: number;
  onChange?: (data: GuestsInputProps['defaultValue']) => void;
  fieldClassName?: string;
}

const GuestsInput: FC<GuestsInputProps> = ({
  defaultValue,
  onChange,
  maxAdult,
  maxChildren,
  maxRoom,
  fieldClassName = '[ nc-hero-field-padding ]',
}) => {
  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(
    defaultValue.guestAdults
  );
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(
    defaultValue.guestChildren
  );
  const [guestRoom, setGuestRoom] = useState(defaultValue.guestRoom);

  useEffect(() => {
    setGuestAdultsInputValue(defaultValue.guestAdults || 0);
    setGuestChildrenInputValue(defaultValue.guestChildren || 0);
    setGuestRoom(defaultValue.guestRoom || 0);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      onChange({
        guestAdults: guestAdultsInputValue,
        guestChildren: guestChildrenInputValue,
        guestRoom: guestRoom,
      });
    }
  }, [guestAdultsInputValue, guestChildrenInputValue, guestRoom]);

  const totalGuests = guestChildrenInputValue + guestAdultsInputValue;

  const renderRoom = () => {
    return (
      <Popover className='relative'>
        {({ open }) => (
          <Fragment>
            <Popover.Button
              className={`flex text-left w-full flex-shrink-0 items-center ${fieldClassName} space-x-3 focus:outline-none cursor-pointer  ${
                open ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
              }`}
            >
              <div className='text-neutral-300 dark:text-neutral-400'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='nc-icon-field'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                  />
                </svg>
              </div>
              <div className='flex-grow'>
                <span className='block xl:text-lg font-semibold'>
                  {`${guestRoom}`} {guestRoom === 1 ? 'Room' : 'Rooms'}
                </span>
                <span className='block mt-1 text-sm text-neutral-400 leading-none font-light'>
                  Rooms
                </span>
                {!!totalGuests && open && (
                  <ClearDataButton
                    onClick={() => {
                      setGuestRoom(1);
                    }}
                  />
                )}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 '>
                <div className='overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5'>
                  <div className='relative bg-white dark:bg-neutral-800 p-4'>
                    <NcInputNumber
                      onChange={(e) => setGuestRoom(e)}
                      min={1}
                      defaultValue={guestRoom}
                      max={maxRoom || 1}
                    />
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Fragment>
        )}
      </Popover>
    );
  };

  const renderGuest = () => {
    return (
      <Popover className='flex relative [ nc-flex-1 ]'>
        {({ open }) => (
          <Fragment>
            <Popover.Button
              className={`flex text-left w-full flex-shrink-0 items-center border-neutral-200 dark:border-neutral-700 border-r ${fieldClassName} space-x-3 focus:outline-none cursor-pointer border-r border-inherit ${
                open ? 'shadow-2xl rounded-full dark:bg-neutral-800' : ''
              }`}
            >
              <div className='text-neutral-300 dark:text-neutral-400'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='nc-icon-field'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={1.5}
                    d='M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z'
                  />
                </svg>
              </div>
              <div className='flex-grow'>
                <span className='block xl:text-lg font-semibold'>
                  {totalGuests || ''} Guests
                </span>
                <span className='block mt-1 text-sm text-neutral-400 leading-none font-light'>
                  {totalGuests ? 'Guests' : 'Add guests'}
                </span>
                {!!totalGuests && open && (
                  <ClearDataButton
                    onClick={() => {
                      setGuestAdultsInputValue(1);
                      setGuestChildrenInputValue(0);
                    }}
                  />
                )}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute right-0 z-10 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl'>
                <NcInputNumber
                  className='w-full'
                  defaultValue={guestAdultsInputValue}
                  onChange={(value) => setGuestAdultsInputValue(value)}
                  max={maxAdult || 1}
                  min={1}
                  label='Adults'
                  desc='Ages 13 or above'
                />
                <NcInputNumber
                  className='w-full mt-6'
                  defaultValue={guestChildrenInputValue}
                  onChange={(value) => setGuestChildrenInputValue(value)}
                  max={maxChildren || 0}
                  label='Children'
                  desc='Ages 0–12'
                />
              </Popover.Panel>
            </Transition>
          </Fragment>
        )}
      </Popover>
    );
  };

  // flex flex-col lg:flex-row lg:items-center w-full flex-shrink-0

  return (
    <div className={` grid grid-cols-2 relative`}>
      {renderGuest()}
      {renderRoom()}
    </div>
  );
};

export default GuestsInput;
