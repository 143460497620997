import { useEffect, useState, FormEvent } from 'react';
import ButtonSubmit from './ButtonSubmit';
import { useAppDispatch, useAppSelector } from 'app/hook';
import HotelLocationInput from './HotelLocationInputForm';

import {
  addUserInput,
  fetchAllTourAsync,
  fetchTourCitiesAsyc,
} from 'app/feature/tour/tourSlice';
import { City, TourUserInput } from 'app/feature/tour/tourInterfaces';

// DEFAULT DATA FOR ARCHIVE PAGE

const TourSearchForm = () => {
  const dispatch = useAppDispatch();
  const [locationInputValue, setLocationInputValue] = useState('');
  const [userInput, setUserInput] = useState<TourUserInput>();

  useEffect(() => {
    dispatch<any>(fetchTourCitiesAsyc());
  }, [dispatch]);

  const { cities } = useAppSelector((state) => state.tour);

  const formatFormData = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    cities.forEach(
      (city: City) =>
        city.cityName === locationInputValue &&
        setUserInput({
          location: city,
        })
    );
  };

  useEffect(() => {
    if (userInput?.location) {
      dispatch(addUserInput(userInput));
      dispatch<any>(fetchAllTourAsync(userInput.location.propertyId));
    }
  }, [userInput]);

  const renderForm = () => {
    return (
      <form
        onSubmit={(ev) => formatFormData(ev)}
        className='w-full relative mt-8 flex flex-col md:flex-row md:items-center rounded-3xl md:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700  md:divide-y-0'
      >
        <HotelLocationInput
          locationInputValue={locationInputValue}
          locations={cities}
          onChange={(ev) => setLocationInputValue(ev)}
        />

        {/* BUTTON SUBMIT OF FORM */}
        <div className='px-4 py-4 lg:py-0'>
          <ButtonSubmit />
        </div>
      </form>
    );
  };

  return renderForm();
};

export default TourSearchForm;
