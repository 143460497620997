import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import hotelReducer from 'app/feature/hotel/hotelSlice';
import busReducer from 'app/feature/bus/busSlice';
import launchReducer from 'app/feature/launch/launchSlice';
import carReducer from 'app/feature/car/carSlice';
import tourReducer from 'app/feature/tour/tourSlice';
import userReducer from 'app/feature/user/userSlices';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

const tourPersistConfig = {
  key: 'tour',
  storage: sessionStorage,
};

const hotelPersistConfig = {
  key: 'hotel',
  storage: sessionStorage,
};

const launchPersistConfig = {
  key: 'launch',
  storage: sessionStorage,
};

const busPersistConfig = {
  key: 'bus',
  storage: sessionStorage,
};

const carPersistConfig = {
  key: 'car',
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  user: userReducer,
  bus: persistReducer(busPersistConfig, busReducer),
  hotel: persistReducer(hotelPersistConfig, hotelReducer),
  launch: persistReducer(launchPersistConfig, launchReducer),
  car: persistReducer(carPersistConfig, carReducer),
  tour: persistReducer(tourPersistConfig, tourReducer),
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
