import { FC } from 'react';
import Logo from 'shared/Logo/Logo';
import Navigation from 'shared/Navigation/Navigation';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import MenuBar from 'shared/MenuBar/MenuBar';
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode';
import { useAppSelector } from 'app/hook';
import AvatarDropdown from './AvatarDropdown';
import NotifyDropdown from './NotifyDropdown';

export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
  const { isLogged, userDetails } = useAppSelector((state) => state.user);

  return (
    <div
      className={`nc-MainNav1 relative z-10 ${
        isTop ? 'onTop ' : 'notOnTop backdrop-filter'
      }`}
    >
      <div className='container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8'>
        <div className='flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14'>
          <Logo />
          <Navigation />
        </div>
        <div className='flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1'>
          <div className='hidden items-center xl:flex space-x-1'>
            <SwitchDarkMode />
            {isLogged && userDetails && <NotifyDropdown />}

            <div className='px-1' />
            {isLogged && userDetails ? (
              <div>
                <AvatarDropdown />
              </div>
            ) : (
              <ButtonPrimary href='/signup'>Sign up</ButtonPrimary>
            )}
          </div>
          <div className='flex items-center xl:hidden'>
            <SwitchDarkMode />
            <div className='px-1' />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
