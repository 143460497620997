import Loading from 'images/loading.gif';

const PageLoading = () => {
  return (
    <div className='w-full h-full'>
      <div className='flex items-center justify-center h-96'>
        <img src={Loading} alt='loading' className='w-24 h-24' />
      </div>
    </div>
  );
};

export default PageLoading;
