import { NavItemType } from 'shared/Navigation/NavigationItem';
import ncNanoId from 'utils/ncNanoId';

const templatesChildrenMenus: NavItemType[] = [
  { id: ncNanoId(), href: '/hotel', name: 'Hotel' },
  { id: ncNanoId(), href: '/bus', name: 'Bus' },
  { id: ncNanoId(), href: '/', name: 'Launch' },
  { id: ncNanoId(), href: '/', name: 'Flight' },
  { id: ncNanoId(), href: '/package-tour', name: 'Package Tour' },
  { id: ncNanoId(), href: '/rental-car', name: 'Rental Car' },
];

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: '/',
    name: 'Home',
  },
  {
    id: ncNanoId(),
    href: '#',
    name: 'Our Services',
    type: 'dropdown',
    children: templatesChildrenMenus,
  },
  {
    id: ncNanoId(),
    href: '/offers',
    name: 'Special Offers',
  },
  {
    id: ncNanoId(),
    href: '/contact-us',
    name: 'Contact us',
  },
  {
    id: ncNanoId(),
    href: '/partnership-with-us',
    name: 'Partnership With Us',
  },
];
