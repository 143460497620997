import { useState, useEffect, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionGridFilterCard from 'containers/PageHotelSearch/SectionGrdiFilterCard';

import { useAppSelector } from 'app/hook';
import {
  fetchOtherRecommendation,
  fetchPeoplesChoice,
  fetchPopularHotel,
} from 'app/feature/hotel/hotelApi';
import { Popular } from 'app/feature/hotel/hotelInterfaces';
import SectionSliderGrid from 'components/SectionSliderGrid/SectionSliderGrid';

const PageHotelSearch = () => {
  const { hotelUserInput } = useAppSelector((state) => state.hotel);
  const [loading, setLoading] = useState(false);
  const [popularHotel, setPopularHotel] = useState<Popular[]>([]);
  const [otherRecommendation, setOtherRecommendation] = useState<Popular[]>([]);
  const [peoplesChoice, setPeoplesChoice] = useState<Popular[]>([]);

  const fetchHotelAsync = async () => {
    const responsePopular = await fetchPopularHotel();
    setPopularHotel(responsePopular.data);
  };

  const fetchRecommendation = async () => {
    const responseRecommendation = await fetchOtherRecommendation();
    setOtherRecommendation(responseRecommendation.data);
  };

  const fetchChoices = async () => {
    const resChoice = await fetchPeoplesChoice();
    setPeoplesChoice(resChoice.data);
  };

  useEffect(() => {
    setLoading(true);
    fetchHotelAsync();
    fetchRecommendation();
    fetchChoices();
    setLoading(false);
  }, []);

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden`}
      data-nc-id='ListingStayPage'
    >
      <Helmet>
        <title>TicketsForTravel | Book Hotel From Anywhere</title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative overflow-hidden'>
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage='Hotel'
          currentTab='Hotel'
          className='pt-10 pb-24 lg:pb-20 lg:pt-20'
        />

        {/* Hotel Filters */}

        {/* SECTION */}
        {hotelUserInput?.location && (
          <SectionGridFilterCard
            className='pb-24 lg:pb-32'
            heading={`Hotels in ${hotelUserInput.location.cityName}`}
          />
        )}

        {/* SECTION : POPULAR HOTEL */}

        {loading ? (
          <Fragment />
        ) : (
          <div className='mb-16'>
            {peoplesChoice.length !== 0 ? (
              <div className='relative py-16'>
                <BackgroundSection />
                <SectionSliderGrid
                  heading={`People's Recommendation`}
                  categoryCardType='card4'
                  itemPerRow={4}
                  sliderStyle='style2'
                  categories={peoplesChoice}
                />
              </div>
            ) : (
              <Fragment />
            )}

            {popularHotel.length !== 0 ? (
              <div className='relative py-16'>
                <SectionSliderGrid
                  heading='Popular Hotels'
                  categoryCardType='card4'
                  itemPerRow={4}
                  sliderStyle='style2'
                  categories={popularHotel}
                />
              </div>
            ) : (
              <Fragment />
            )}

            {otherRecommendation.length !== 0 ? (
              <div className='relative py-16'>
                <BackgroundSection />
                <SectionSliderGrid
                  heading='Others Recommendation'
                  categoryCardType='card4'
                  itemPerRow={4}
                  sliderStyle='style2'
                  categories={otherRecommendation}
                />
              </div>
            ) : (
              <Fragment />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHotelSearch;
