const TrainSearchForm = () => {
  return (
    <div className='w-full relative mt-8 p-8 flex flex-col items-center rounded-3xl  shadow-2xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200'>
      <span className='text-xl'>
        Please visit{' '}
        <a
          href='https://www.esheba.cnsbd.com'
          className=' text-blue-800'
          target={'_blank'}
          rel='noreferrer'
        >
          {' '}
          Here
        </a>
      </span>
    </div>
  );
};

export default TrainSearchForm;
