import { FC } from 'react';
import { Helmet } from 'react-helmet';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SectionGridFeatureProperty from './SectionGridFeatureProperty';
import RoundtripSectionGridFeature from './RoundtripSectionGridFeature';

import OneWayIcon from 'images/extra/one-way.svg';
import TwoWayIcon from 'images/extra/two-way.svg';

import { useAppSelector } from 'app/hook';
import Heading from 'components/Heading/Heading';

export interface PageBusSearchProps {
  className?: string;
}

const PageBusSearch: FC<PageBusSearchProps> = ({ className = '' }) => {
  const { busUserInput, firstBusSelected } = useAppSelector(
    (state) => state.bus
  );

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id='ListingStayPage'
    >
      <Helmet>
        <title>TicketsForTravel | Buy Bus Tickets Online</title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative overflow-hidden'>
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage='Bus'
          currentTab='Bus'
          className='pt-10 pb-24 lg:pb-20 lg:pt-20'
        />

        {busUserInput?.fromCity && busUserInput?.toCity && (
          <div className={`mb-12 lg:mb-16 ${className}`}>
            <Heading
              desc={
                busUserInput.roundTrip
                  ? firstBusSelected
                    ? `Choose a bus for ${busUserInput?.toCity.locName} to
          ${busUserInput?.fromCity.locName}`
                    : `Choose a bus for ${busUserInput?.fromCity.locName} to
          ${busUserInput?.toCity.locName}`
                  : ''
              }
            >
              <span className='flex align-middle text-4xl font-semibold'>
                Bus in {busUserInput.fromCity.locName}
                {busUserInput.roundTrip ? (
                  <img
                    src={TwoWayIcon}
                    alt='Two Way Icon'
                    className='w-11 h-auto mx-4'
                  />
                ) : (
                  <img
                    src={OneWayIcon}
                    alt='One Way Icon'
                    className='w-11 h-auto mx-4'
                  />
                )}
                {busUserInput.toCity.locName}
              </span>
            </Heading>
          </div>
        )}

        {busUserInput?.roundTrip ? (
          <RoundtripSectionGridFeature variant='Bus' />
        ) : (
          <SectionGridFeatureProperty variant='Bus' />
        )}

        {/* SECTION */}
        <SectionSubscribe2 className='py-24 lg:py-32' />
      </div>
    </div>
  );
};

export default PageBusSearch;
