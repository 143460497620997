import { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import { Popular } from 'app/feature/hotel/hotelInterfaces';
import { StarIcon } from '@heroicons/react/solid';

export interface CardCategory4Props {
  className?: string;
  taxonomy: Popular;
}

const CardCategory4: FC<CardCategory4Props> = ({
  className = '',
  taxonomy,
}) => {
  const { images, name, starRating, city } = taxonomy;

  let outArr = Array.from(Array(starRating), (_, x) => x);

  return (
    <div
      className={`nc-CardCategory4 flex flex-col ${className}`}
      data-nc-id='CardCategory4'
    >
      <div
        className={`flex-shrink-0 relative w-full aspect-w-5 aspect-h-4 sm:aspect-h-6 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={images[0]}
          className='object-cover w-full h-full rounded-2xl'
        />
        <span className='opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity'></span>
      </div>
      <div className='mt-4 px-2 truncate'>
        <h2
          className={`text-base sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}
        >
          {name}
        </h2>

        <div className='flex mt-2 justify-between'>
          <span>{city.cityName}</span>
          <span
            className={`flex text-sm text-neutral-6000 dark:text-neutral-400`}
          >
            {outArr.map((o) => (
              <StarIcon key={o} className='w-5 h-5 text-red-500 m-0 p-0' />
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CardCategory4;
