import { FC } from 'react';
import { Helmet } from 'react-helmet';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import SectionGridFilterCard from './SectionGridFilterCard';

export interface PageCarSearchProps {
  className?: string;
}

const PageCarSearch: FC<PageCarSearchProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id='ListingCarPage'
    >
      <Helmet>
        <title>Rental Car | Tickets For Travel</title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative'>
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage='Rent A Car'
          currentTab='Rent A Car'
          className='pt-10 pb-24 lg:pb-20 lg:pt-20'
        />

        {/* SECTION */}
        <SectionGridFilterCard className='pb-24 lg:pb-32' />

        {/* SECTION */}
        <SectionSubscribe2 className='py-24 lg:py-32' />
      </div>
    </div>
  );
};

export default PageCarSearch;
