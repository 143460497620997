import axios from 'axios';
import { OrderTypes } from './hotelInterfaces';

const URL = process.env.REACT_APP_SERVER_URL_HOTEL;

export const fetchHotelLocation = async () => {
  const response = await axios.get(
    `${URL}api/v1/search-items?fields=cityName,cityId,propertyId&variant=city`
  );
  return response.data;
};

export const fetchAllHotelList = async (
  propertyCode: string,
  order?: OrderTypes,
  checkin?: string,
  checkOut?: string,
  page?: number,
  limit?: number,
  priceRange?: number[],
  rating?: string
) => {
  let extraUri = `&page=${page}&limit=${limit}${
    order === 'default' ? '' : `&sort=${order}`
  }${
    priceRange
      ? `&costPerNight[gte]=${priceRange[0]}&costPerNight[lt]=${priceRange[1]}`
      : ''
  }${rating ? `&starRating=${rating}` : ''}`;

  const response: any = await axios.get(
    `${URL}api/v1/hotel-list?propertyCode=${propertyCode}&checkin=${checkin}&checkout=${checkOut}${extraUri}`
  );

  return response.data;
};

export const fetchSingleHotel = async (
  id: string,
  checkin?: string,
  checkOut?: string
) => {
  const response = await axios.get(
    `${URL}api/v1/hotel-list/${id}?checkin=${checkin}&checkout=${checkOut}`
  );
  return response.data;
};

export const fetchPaymentHotel = async (body: any, token: string) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.post(
      `${URL}api/v1/bookings/checkout-sslcommerz`,
      body,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchVat = async () => {
  try {
    const response = await axios.get(`${URL}api/v1/vat-service-charge`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchPopularHotel = async () => {
  try {
    const response = await axios.get(`${URL}api/v1/hotel-list/popular-hotels`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchOtherRecommendation = async () => {
  try {
    const response = await axios.get(
      `${URL}api/v1/hotel-list/others-recommendation`
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchPeoplesChoice = async () => {
  try {
    const response = await axios.get(`${URL}api/v1/hotel-list/peoples-choice`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};
