import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import { Link } from 'react-router-dom';
import SSL from 'images/footerSSL.png';

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: '1',
    title: 'Explore',
    menus: [
      { href: '/about-us', label: 'About Us' },
      { href: '#', label: 'Our Teams' },
      { href: '#', label: 'Design systems' },
      { href: '#', label: 'Pricing' },
      { href: '#', label: 'Reviews' },
    ],
  },
  {
    id: '2',
    title: 'Terms & Conditions',
    menus: [
      { href: '/terms-and-conditions', label: 'Terms & Conditions' },
      { href: '/privacy-policy', label: 'Privacy Policy' },
      { href: '/refund-policy', label: 'Refund Policy' },
      { href: '#', label: 'Learn design' },
      { href: '#', label: 'Learn about tour' },
    ],
  },
  {
    id: '3',
    title: 'Getting started',
    menus: [
      { href: '/partnership-with-us', label: 'Work With Us' },
      { href: '/contact-us', label: 'Contact Us' },
      { href: '/offers', label: 'Special Discounts' },
      { href: '#', label: 'Browser Support' },
      { href: '#', label: 'Tour Guide' },
    ],
  },
];

const Footer = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className='text-sm'>
        <h2 className='font-semibold text-neutral-700 dark:text-neutral-200'>
          {menu.title}
        </h2>
        <ul className='mt-5 space-y-4'>
          {menu.menus.map((item, index) => (
            <li key={index}>
              <Link
                to={item.href}
                key={index}
                className='text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white'
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <footer className='nc-Footer relative py-24 border-t border-neutral-200 dark:border-neutral-700'>
      <div className='container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 '>
        <div className='grid grid-cols-4 gap-5 col-span-2 md:col-span-3 lg:md:col-span-1 lg:flex lg:flex-col'>
          <div className='col-span-2 md:col-span-1'>
            <Logo />
          </div>
          <div className='col-span-2 flex items-center md:col-span-4'>
            <SocialsList1 className='flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start' />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>

      <div className='container pt-12 '>
        <img src={SSL} alt='SSL Commmerze' className='w-full h-auto' />
      </div>
    </footer>
  );
};

export default Footer;
