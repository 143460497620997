import { useEffect, useState, FormEvent } from 'react';
import { FocusedInputShape } from 'react-dates';
import { useHistory } from 'react-router';
import RentalCarDatesRangeInput from './RentalCarDatesRangeInput';
import ButtonSubmit from './ButtonSubmit';
import moment from 'moment';
import CarCityInput from './CarCityInput';
import {
  fetchCarCitiesAsync,
  fetchCarListAsync,
  addUserInput,
} from 'app/feature/car/carSlice';
import { City, CarUserInput } from 'app/feature/car/carInterfaces';
import { useAppDispatch, useAppSelector } from 'app/hook';
import CarRegionInput from './CarRegionInput';

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TimeRage {
  startTime: string;
  endTime: string;
}

const RentalCarSearchForm = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });

  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: '10:00 AM',
    endTime: '10:00 AM',
  });

  useEffect(() => {
    dispatch<any>(fetchCarCitiesAsync());
  }, [dispatch]);

  const { cities, carUserInput } = useAppSelector((state) => state.car);

  const originInputValue = carUserInput?.fromCity.cityName || '';

  const [userInput, setUserInput] = useState<CarUserInput>();
  const [origin, setOrigin] = useState<City>();
  const [region, setRegion] = useState('');
  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | 'dropOffInput' | null
  >(null);

  // Here same === Hire a car
  // Here different === Inter-city

  const [dropOffLocationType, setDropOffLocationType] = useState<
    'same' | 'different'
  >('same');

  const setOriginHandler = (ev: string) => {
    setRegion('');
    cities.forEach((city: City) => city.cityName === ev && setOrigin(city));
  };

  const formSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      origin &&
      region &&
      dateRangeValue.startDate &&
      dateRangeValue.endDate &&
      timeRangeValue.startTime
    ) {
      setUserInput({
        fromCity: origin,
        fromRegion: region,
        depDate: dateRangeValue.startDate.toISOString(),
        retDate: dateRangeValue.endDate.toISOString(),
        depTime: timeRangeValue.startTime,
      });
    }
  };

  useEffect(() => {
    if (
      userInput?.fromCity &&
      userInput.fromRegion &&
      userInput.depDate &&
      userInput.retDate &&
      userInput.depTime
    ) {
      dispatch(addUserInput(userInput));
      dispatch<any>(fetchCarListAsync(userInput));
      history.push('/rent-a-car');
    }
  }, [userInput]);

  const renderRadioBtn = () => {
    return (
      <div className=' py-5 [ nc-hero-field-padding ] flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:space-x-10 border-b border-neutral-100 dark:border-neutral-900'>
        <div className='flex  items-center'>
          <input
            id='same-drop-off'
            name='drop-off-type'
            type='radio'
            value='same'
            className='focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300'
            checked={dropOffLocationType === 'same'}
            onChange={(e) =>
              setDropOffLocationType(e.currentTarget.value as 'same')
            }
          />
          <label
            htmlFor='same-drop-off'
            className='ml-2 sm:ml-3 block text-sm font-medium text-gray-700 dark:text-neutral-300'
          >
            Hire A Car
          </label>
        </div>
        <div className='flex items-center'>
          <input
            id='different-drop-off'
            name='drop-off-type'
            value='different'
            type='radio'
            className='focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300'
            checked={dropOffLocationType === 'different'}
            onChange={(e) =>
              setDropOffLocationType(e.currentTarget.value as 'different')
            }
          />
          <label
            htmlFor='different-drop-off'
            className='ml-2 sm:ml-3 block text-sm font-medium text-gray-700 dark:text-neutral-300'
          >
            Inter-city
          </label>
        </div>
      </div>
    );
  };

  const renderHireACarForm = () => {
    return (
      <form
        onSubmit={formSubmit}
        className='w-full relative rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 flex flex-col md:flex-row md:items-center [ nc-divide-field ] '
      >
        <div className='relative flex flex-col md:flex-row flex-grow [ nc-divide-field ] '>
          <CarCityInput
            originInputValue={originInputValue}
            city={cities}
            onChange={(ev) => setOriginHandler(ev)}
            placeHolder='From'
            desc='Pick Up City'
          />

          {origin && (
            <CarRegionInput
              regions={origin.regions}
              defaultValue={region}
              onChange={(e) => setRegion(e)}
              onInputDone={() => setFieldFocused('startDate')}
              placeHolder='Area'
              desc='Pick Up location'
              autoFocus={fieldFocused === 'dropOffInput'}
            />
          )}
        </div>

        <RentalCarDatesRangeInput
          defaultDateValue={dateRangeValue}
          defaultTimeValue={timeRangeValue}
          defaultFocus={fieldFocused === 'dropOffInput' ? null : fieldFocused}
          onFocusChange={(focus) => setFieldFocused(focus)}
          onChange={(data) => {
            setDateRangeValue(data.stateDate);
            setTimeRangeValue(data.stateTimeRage);
          }}
        />

        <div className='px-4 py-3'>
          <ButtonSubmit />
        </div>
      </form>
    );
  };

  const renderInterCity = () => {
    return <span>Contact us: 8545555656</span>;
  };

  const renderForm = () => {
    return (
      <div className='w-full'>
        <div className='w-full relative mt-8 rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800'>
          {renderRadioBtn()}
          {dropOffLocationType === 'same' && renderHireACarForm()}
          {dropOffLocationType === 'different' && renderInterCity()}
        </div>
      </div>
    );
  };

  return renderForm();
};

export default RentalCarSearchForm;
