import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchAllTour,
  fetchOneTour,
  fetchTourCities,
  fetchVat,
} from './tourApi';

import {
  CitiesApiCall,
  OneTourApiCall,
  TourListApiCall,
  TourState,
  VatAndServiceApiCall,
} from './tourInterfaces';

const initialState: TourState = {
  status: 'idle',
  tours: [],
  cities: [],
};

export const fetchTourCitiesAsyc = createAsyncThunk(
  'tour/fetchCities',
  async () => {
    const { data }: CitiesApiCall = await fetchTourCities();
    return data;
  }
);

export const fetchAllTourAsync = createAsyncThunk(
  'tour/fetchTourList',
  async (propertyId?: string) => {
    const { data }: TourListApiCall = await fetchAllTour(propertyId);
    return data;
  }
);

export const fetchOneTourAsync = createAsyncThunk(
  'tour/fetchOneTour',
  async (id: string) => {
    const { data }: OneTourApiCall = await fetchOneTour(id);
    return data;
  }
);

export const fetchVatAsync = createAsyncThunk('car/fetchVat', async () => {
  const { data }: VatAndServiceApiCall = await fetchVat();
  return data;
});

export const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    addUserInput: (state, action) => {
      state.tourUserInput = action.payload;
    },
    addFinalInput: (state, action) => {
      state.tourFinalInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTourCitiesAsyc.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTourCitiesAsyc.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cities = action.payload;
      })
      .addCase(fetchTourCitiesAsyc.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAllTourAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllTourAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.tours = action.payload.docs;
      })
      .addCase(fetchAllTourAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchOneTourAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOneTourAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.oneTour = action.payload;
      })
      .addCase(fetchOneTourAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchVatAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVatAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.vatAndServices = action.payload;
      })
      .addCase(fetchVatAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addUserInput, addFinalInput } = tourSlice.actions;

export default tourSlice.reducer;
