import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import NcImage from 'shared/NcImage/NcImage';
import TickSVG from 'images/tick.svg';
import { Link, useParams } from 'react-router-dom';
import { fetchVerifyEmail } from 'app/feature/user/userApi';
import PageLoading from 'containers/PageLoading/PageLoading';

const PageEmailConfirm = () => {
  const { token }: any = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const fetchConfirmUserAsync = async () => {
    setLoading(true);
    try {
      await fetchVerifyEmail(token);
      setLoading(false);
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConfirmUserAsync();
  }, []);

  return (
    <div className={`nc-PageContact overflow-hidden`} data-nc-id='PageContact'>
      <Helmet>
        <title>Email Confirm | TicketsForTravel</title>
      </Helmet>

      <div className='container relative pt-5 pb-16 lg:pb-20 lg:pt-5'>
        {/* HEADER */}

        {loading ? (
          <PageLoading />
        ) : (
          <header className='text-center max-w-2xl mx-auto space-y-2'>
            <div className='flex my-20 justify-center'>
              <NcImage src={TickSVG} className='w-48 h-48' />
            </div>

            <h2 className='text-3xl text-neutral-800 dark:text-neutral-800'>
              Congratulations!
            </h2>
            <span className='block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium'>
              Your account is activated.{' '}
              <Link to='/login'>
                <span>Login</span>
              </Link>{' '}
              to continue
            </span>
          </header>
        )}

        {error && (
          <h2 className='text-3xl text-red-400 dark:text-red-400'>{error}</h2>
        )}
      </div>
    </div>
  );
};

export default PageEmailConfirm;
