import { Fragment, useState, FC } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { HotelRatingType } from 'data/types';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonThird from 'shared/Button/ButtonThird';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Checkbox from 'shared/Checkbox/Checkbox';
import { Range } from 'rc-slider';
import convertNumbThousand from 'utils/convertNumbThousand';
import { OrderTypes } from 'app/feature/hotel/hotelInterfaces';

// DEMO DATA

export interface HotelFiltersProps {
  className?: string;
  orderTypeState: OrderTypes;
  onOrderChange: (value: OrderTypes) => void;
  minPrice: number;
  maxPrice: number;
  onPriceRangeChange: (value: number[]) => void;
  onRatingChange: (value: string) => void;
}

const HotelFilters: FC<HotelFiltersProps> = ({
  className = '',
  orderTypeState,
  onOrderChange,
  minPrice,
  maxPrice,
  onPriceRangeChange,
  onRatingChange,
}) => {
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);
  const [ratingList, setRatingList] = useState<HotelRatingType[]>([
    {
      name: '1 Star ⭐',
      id: 1,
      checked: true,
    },
    {
      name: '2 Star ⭐⭐',
      id: 2,
      checked: true,
    },
    {
      name: '3 Star ⭐⭐⭐',
      id: 3,
      checked: true,
    },
    {
      name: '4 Star ⭐⭐⭐⭐',
      id: 4,
      checked: true,
    },
    {
      name: '5 Star ⭐⭐⭐⭐⭐',
      id: 5,
      checked: true,
    },
  ]);

  const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const optionHandler = (value: OrderTypes, close: any) => {
    onOrderChange(value);
    close();
  };

  const priceRangeHandler = (changedValue: number[], close: any) => {
    onPriceRangeChange(changedValue);
    close();
  };

  let newItems: HotelRatingType[] = [];

  const handleCheckbox = (item: HotelRatingType, index: number) => {
    newItems = ratingList.slice();
    newItems[index].checked = !newItems[index].checked;
  };

  const ratingHandler = (close: any) => {
    if (newItems.length !== 0) {
      setRatingList(newItems);
    }

    onRatingChange(
      ratingList
        .filter((item) => item.checked === true)
        .map((list) => list.id)
        .join(',')
    );

    close();
  };

  const renderXClear = () => {
    return (
      <span className='w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='h-3 w-3'
          viewBox='0 0 20 20'
          fill='currentColor'
        >
          <path
            fillRule='evenodd'
            d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
            clipRule='evenodd'
          />
        </svg>
      </span>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className='relative'>
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none `}
            >
              <span>
                {`BDT ${convertNumbThousand(
                  rangePrices[0]
                )} - BDT ${convertNumbThousand(rangePrices[1])}`}{' '}
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 '>
                <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
                  <div className='relative flex flex-col px-5 py-6 space-y-8'>
                    <div className='space-y-5'>
                      <span className='font-medium'>Price per day</span>
                      <Range
                        className='text-red-400'
                        min={minPrice}
                        max={maxPrice}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={setRangePrices}
                      />
                    </div>

                    <div className='flex justify-between space-x-5'>
                      <div>
                        <label
                          htmlFor='minPrice'
                          className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
                        >
                          Min price
                        </label>
                        <div className='mt-1 relative rounded-md'>
                          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                            <span className='text-neutral-500 sm:text-sm'>
                              ৳
                            </span>
                          </div>
                          <input
                            type='text'
                            name='minPrice'
                            disabled
                            id='minPrice'
                            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor='maxPrice'
                          className='block text-sm font-medium text-neutral-700 dark:text-neutral-300'
                        >
                          Max price
                        </label>
                        <div className='mt-1 relative rounded-md'>
                          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                            <span className='text-neutral-500 sm:text-sm'>
                              ৳
                            </span>
                          </div>
                          <input
                            type='text'
                            disabled
                            name='maxPrice'
                            id='maxPrice'
                            className='focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900'
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end'>
                    <ButtonPrimary
                      onClick={() =>
                        priceRangeHandler(
                          [rangePrices[0], rangePrices[1]],
                          close
                        )
                      }
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderHotelRating = () => {
    return (
      <Popover className='relative'>
        {({ open, close }) => (
          <Fragment>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none bg-primary-50  ${
                open ? '!border-primary-500 ' : ''
              } ${
                ratingList.filter((item) => item.checked === true).length !== 0
                  ? 'text-primary-700 focus:outline-none !border-primary-500'
                  : ''
              }`}
            >
              <span>
                Rating{' '}
                {ratingList.filter((item) => item.checked === true).length !==
                  0 &&
                  `(${
                    ratingList.filter((item) => item.checked === true).length
                  })`}
              </span>
              <i className='las la-angle-down ml-2'></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md'>
                <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
                  <div className='relative flex flex-col px-5 py-6 space-y-5'>
                    {ratingList.map((item, index) => (
                      <div key={item.id}>
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={!!item.checked}
                          onChange={() => handleCheckbox(item, index)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className='p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-end'>
                    <ButtonPrimary
                      onClick={() => ratingHandler(close)}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Fragment>
        )}
      </Popover>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <div
          className={`flex lg:hidden items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span>More filters (3)</span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as='div'
            className='fixed inset-0 z-50 overflow-y-auto'
            onClose={closeModalMoreFilterMobile}
          >
            <div className='min-h-screen text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Dialog.Overlay className='fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60' />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className='inline-block h-screen align-middle'
                aria-hidden='true'
              >
                &#8203;
              </span>
              <Transition.Child
                className='inline-block py-8 h-screen w-full'
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <div className='inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full'>
                  <div className='relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-medium leading-6 text-gray-900'
                    >
                      More filters
                    </Dialog.Title>
                    <span className='absolute left-3 top-3'>
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className='p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between'>
                    <ButtonThird
                      onClick={closeModalMoreFilterMobile}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilterMobile}
                      sizeClass='px-4 py-2 sm:px-5'
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  const renderSelectOption = () => {
    return (
      <Popover className='relative'>
        {({ open, close }) => (
          <Fragment>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? '!border-primary-500 ' : ''
              }`}
            >
              <span>
                {orderTypeState === '-costPerNight'
                  ? 'Price (High > Low)'
                  : orderTypeState === 'costPerNight'
                  ? 'Price (Low > High)'
                  : 'Default'}
              </span>
              <i className='las la-angle-down ml-2'></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 '>
                <div className='overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700'>
                  <div className='relative flex flex-col px-5 py-6 space-y-5'>
                    <option
                      className='cursor-pointer'
                      onClick={() => optionHandler('default', close)}
                    >
                      {'Default'}
                    </option>

                    <option
                      className='cursor-pointer'
                      onClick={() => optionHandler('costPerNight', close)}
                    >
                      {'Price (Low > High)'}
                    </option>

                    <option
                      className='cursor-pointer'
                      onClick={() => optionHandler('-costPerNight', close)}
                    >
                      {'Price (High > Low)'}
                    </option>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Fragment>
        )}
      </Popover>
    );
  };

  return (
    <div className={`flex lg:space-x-4 ${className}`}>
      <div className='hidden lg:flex space-x-4'>
        {renderSelectOption()}
        {renderTabsPriceRage()}
        {renderHotelRating()}
      </div>
      {renderTabMoreFilterMobile()}
    </div>
  );
};

export default HotelFilters;
