import { FC } from 'react';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import { Link } from 'react-router-dom';
import { Tour } from 'app/feature/tour/tourInterfaces';

export interface TourCardProps {
  className?: string;
  ratioClass?: string;
  tour: Tour;
  size?: 'default' | 'small';
}

const TourCard: FC<TourCardProps> = ({
  size = 'default',
  className = '',
  tour,
  ratioClass = 'aspect-w-3 aspect-h-3',
}) => {
  const {
    _id,
    images,
    location,
    destination,
    price,
    countries,
    numOfDays,
    numOfNights,
  } = tour;

  const renderSliderGallery = () => {
    return (
      <div className='relative w-full rounded-2xl overflow-hidden'>
        <GallerySlider
          uniqueID={`ExperiencesCard-${_id}`}
          ratioClass={ratioClass}
          galleryImgs={images}
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className='py-4 space-y-4'>
        <div className='space-y-2'>
          <div className='flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-4 w-4'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
                d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
              />
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={1.5}
                d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
              />
            </svg>

            <span className=''>
              {location.cityName}, {countries.join(', ')}
            </span>
          </div>

          <div className='flex items-center space-x-2'>
            <h2 className={` font-medium capitalize text-base `}>
              <span className='line-clamp-1'>{destination}</span>
            </h2>
          </div>
        </div>
        <div className='border-b border-neutral-100 dark:border-neutral-800'></div>
        <div className='flex justify-between items-center'>
          <span>
            {numOfDays} days & {numOfNights} nights
          </span>
          <span className='text-base font-semibold'>
            BDT {price}
            {` `}
            {size === 'default' && (
              <span className='text-sm text-neutral-500 dark:text-neutral-400 font-normal'>
                /person
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ExperiencesCard group relative ${className}`}
      data-nc-id='ExperiencesCard'
    >
      <Link to={`package-tour/${_id}`}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default TourCard;
