import { FC } from 'react';
import { Link } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';
import { Car } from 'app/feature/car/carInterfaces';
import GallerySlider from 'components/GallerySlider/GallerySlider';

export interface CarCardProps {
  className?: string;
  data?: Car;
  size?: 'default' | 'small';
  ratioClass?: string;
}

// const DEMO_DATA: CarDataType = DEMO_CAR_LISTINGS[0];

const CarCard: FC<CarCardProps> = ({
  size = 'default',
  className = '',
  data,
  ratioClass,
}) => {
  const renderSliderGallery = () => {
    return (
      <div className='relative w-full'>
        {data?.images && (
          <GallerySlider
            uniqueID={`car-${data._id}`}
            ratioClass={ratioClass}
            galleryImgs={data.images}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === 'default' ? 'p-5  space-y-4' : 'p-3  space-y-2'}>
        <div className='space-y-2'>
          <div className='flex items-center space-x-2'>
            {data?.AC && <Badge name='AC' />}
          </div>
          <div>
            <h2
              className={`  capitalize ${
                size === 'default'
                  ? 'text-xl font-semibold'
                  : 'text-base font-medium'
              }`}
            >
              <span className='line-clamp-1'>{data?.vehicleName}</span>
            </h2>
          </div>
          <div className='flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2'>
            <span className=''>{data?.agentName}</span>
          </div>
        </div>
        <div className='w-14  border-b border-neutral-100 dark:border-neutral-800'></div>
        <div className='flex justify-between items-center'>
          <span>{data?.passengerCapacity} seats</span>

          <span className='text-base font-semibold'>
            {data?.dailyRent}
            {` `}
            {size === 'default' && (
              <span className='text-sm text-neutral-500 dark:text-neutral-400 font-normal'>
                /day
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 ${className}`}
      data-nc-id='CarCard'
    >
      <Link to={`/rent-a-car/${data?._id}`} className='flex flex-col'>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default CarCard;
