import { FC } from 'react';
import CardCategoryBox1 from 'components/CardCategoryBox1/CardCategoryBox1';
import Heading from 'components/Heading/Heading';
import { Popular } from 'app/feature/hotel/hotelInterfaces';

export interface SectionGridCategoryBoxProps {
  categories?: Popular[];
  headingCenter?: boolean;
  categoryCardType?: 'card1';
  className?: string;
  gridClassName?: string;
}

// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: '1',
//     href: '#',
//     name: 'New Yourk',
//     taxonomy: 'category',
//     count: 1882,
//     thumbnail:
//       'https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
//   },
//   {
//     id: '2',
//     href: '#',
//     name: 'Singapore',
//     taxonomy: 'category',
//     count: 8288,
//     thumbnail:
//       'https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
//   },
//   {
//     id: '2',
//     href: '#',
//     name: 'Paris',
//     taxonomy: 'category',
//     count: 1288,
//     thumbnail:
//       'https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
//   },
//   {
//     id: '2',
//     href: '#',
//     name: 'London',
//     taxonomy: 'category',
//     count: 112,
//     thumbnail:
//       'https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
//   },
//   {
//     id: '2',
//     href: '#',
//     name: 'Tokyo',
//     taxonomy: 'category',
//     count: 323,
//     thumbnail:
//       'https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
//   },
//   {
//     id: '2',
//     href: '#',
//     name: 'Maldives',
//     taxonomy: 'category',
//     count: 2223,
//     thumbnail:
//       'https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
//   },
//   {
//     id: '11',
//     href: '#',
//     name: 'New Yourk',
//     taxonomy: 'category',
//     count: 1775,
//     thumbnail:
//       'https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
//   },
//   {
//     id: '222',
//     href: '#',
//     name: 'Singapore',
//     taxonomy: 'category',
//     count: 1288,
//     thumbnail:
//       'https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
//   },
// ];

const SectionGridCategoryBox: FC<SectionGridCategoryBoxProps> = ({
  // categories = DEMO_CATS,
  categoryCardType = 'card1',
  headingCenter = true,
  className = '',
  gridClassName = 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
}) => {
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case 'card1':
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading isCenter={headingCenter}>Explore Bangladesh</Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {/* {categories.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))} */}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
