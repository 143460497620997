import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchCarCities,
  fetchCarList,
  fetchSingleCar,
  fetchVat,
} from './carApi';
import {
  RentalCarState,
  CitiesApiCall,
  CarListApiCall,
  CarUserInput,
  SingleCarApiCall,
  VatAndServiceApiCall,
} from './carInterfaces';

const initialState: RentalCarState = {
  status: 'idle',
  cities: [],
  cars: [],
};

export const fetchCarCitiesAsync = createAsyncThunk(
  'car/fetchCities',
  async () => {
    const { data }: CitiesApiCall = await fetchCarCities();
    return data;
  }
);

export const fetchCarListAsync = createAsyncThunk(
  'car/fetchCarList',
  async (userData: CarUserInput) => {
    const { fromCity, fromRegion, depDate, retDate } = userData;
    const {
      data: { docs },
    }: CarListApiCall = await fetchCarList(
      fromCity.cityId,
      fromRegion,
      depDate,
      retDate
    );

    return docs;
  }
);

export const fetchSingleCarAsync = createAsyncThunk(
  'car/fetchSingleCar',
  async (userChosenData: { id: string }) => {
    const { id } = userChosenData;
    const { data }: SingleCarApiCall = await fetchSingleCar(id);
    return data;
  }
);

export const fetchVatAsync = createAsyncThunk('car/fetchVat', async () => {
  const { data }: VatAndServiceApiCall = await fetchVat();
  return data;
});

export const carSlice = createSlice({
  name: 'car',
  initialState,
  reducers: {
    addUserInput: (state, action) => {
      state.carUserInput = action.payload;
    },
    addFinalInput: (state, action) => {
      state.carFinalInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarCitiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCarCitiesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cities = action.payload;
      })
      .addCase(fetchCarCitiesAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCarListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCarListAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cars = action.payload;
      })
      .addCase(fetchCarListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSingleCarAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleCarAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.oneCar = action.payload;
      })
      .addCase(fetchSingleCarAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchVatAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVatAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.vatAndServices = action.payload;
      })
      .addCase(fetchVatAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { addUserInput, addFinalInput } = carSlice.actions;

export default carSlice.reducer;
