import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const PageTermsConditions = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>Terms And Conditions | TicketsForTravel</title>
      </Helmet>

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <h2 className='text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100'>
          Terms & Conditions
        </h2>

        <div className='block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1'>
          <p>
            These terms and conditions outline the rules and regulations for the
            use of Tickets4Travel Ltd.'s Website, located at
            https://tickets4travel.com/.
          </p>

          <p className='my-3'>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use Tickets4Travel if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>

          <p className='my-3'>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company’s terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client’s needs in respect of
            provision of the Company’s stated services, in accordance with and
            subject to, prevailing law of Netherlands. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </p>

          <h3 className='text-xl font-semibold my-5'>Cookies</h3>

          <p className='my-3'>
            We employ the use of cookies. By accessing Tickets4Travel, you
            agreed to use cookies in agreement with the Tickets4Travel Ltd.'s
            Privacy Policy.
          </p>

          <p className='my-3'>
            Most interactive websites use cookies to let us retrieve the user’s
            details for each visit. Cookies are used by our website to enable
            the functionality of certain areas to make it easier for people
            visiting our website. Some of our affiliate/advertising partners may
            also use cookies. License
          </p>

          <p className='my-3'>
            Unless otherwise stated, Tickets4Travel Ltd. and/or its licensors
            own the intellectual property rights for all material on
            Tickets4Travel. All intellectual property rights are reserved. You
            may access this from Tickets4Travel for your own personal use
            subjected to restrictions set in these terms and conditions. You
            must not:
          </p>

          <p className='my-3'>
            Republish material from Tickets4Travel Sell, rent or sub-license
            material from Tickets4Travel Reproduce, duplicate or copy material
            from Tickets4Travel Redistribute content from Tickets4Travel Parts
            of this website offer an opportunity for users to post and exchange
            opinions and information in certain areas of the website.
            Tickets4Travel Ltd. does not filter, edit, publish or review
            Comments prior to their presence on the website. Comments do not
            reflect the views and opinions of Tickets4Travel Ltd., its agents
            and/or affiliates. Comments reflect the views and opinions of the
            person who post their views and opinions. To the extent permitted by
            applicable laws, Tickets4Travel Ltd. shall not be liable for the
            Comments or for any liability, damages or expenses caused and/or
            suffered as a result of any use of and/or posting of and/or
            appearance of the Comments on this website. Tickets4Travel Ltd.
            reserves the right to monitor all Comments and to remove any
            Comments which can be considered inappropriate, offensive or causes
            breach of these Terms and Conditions. You warrant and represent
            that:
          </p>

          <p className='my-3'>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so; The Comments do not invade
            any intellectual property right, including without limitation
            copyright, patent or trademark of any third party; The Comments do
            not contain any defamatory, libelous, offensive, indecent or
            otherwise unlawful material which is an invasion of privacy The
            Comments will not be used to solicit or promote business or custom
            or present commercial activities or unlawful activity. You hereby
            grant Tickets4Travel Ltd. a non-exclusive license to use, reproduce,
            edit and authorize others to use, reproduce and edit any of your
            Comments in any and all forms, formats or media.
          </p>

          <p className='my-3'>
            Hyperlinking to our Content The following organizations may link to
            our Website without prior written approval:
          </p>

          <p className='my-3'>
            Government agencies; Search engines; News organizations; Online
            directory distributors may link to our Website in the same manner as
            they hyperlink to the Websites of other listed businesses; and
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site. These organizations
            may link to our home page, to publications or to other Website
            information so long as the link: (a) is not in any way deceptive;
            (b) does not falsely imply sponsorship, endorsement or approval of
            the linking party and its products and/or services; and (c) fits
            within the context of the linking party’s site.
          </p>

          <p className='my-3'>
            We may consider and approve other link requests from the following
            types of organizations:
          </p>

          <p className='my-3'>
            commonly-known consumer and/or business information sources; dot.com
            community sites; associations or other groups representing
            charities; online directory distributors; internet portals;
            accounting, law and consulting firms; and educational institutions
            and trade associations. We will approve link requests from these
            organizations if we decide that: (a) the link would not make us look
            unfavorably to ourselves or to our accredited businesses; (b) the
            organization does not have any negative records with us; (c) the
            benefit to us from the visibility of the hyperlink compensates the
            absence of Tickets4Travel Ltd.; and (d) the link is in the context
            of general resource information.
          </p>

          <p className='my-3'>
            These organizations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party’s site.
          </p>

          <p className='my-3'>
            If you are one of the organizations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an e-mail to Tickets4Travel Ltd. Please include your name,
            your organization name, contact information as well as the URL of
            your site, a list of any URLs from which you intend to link to our
            Website, and a list of the URLs on our site to which you would like
            to link. Wait 2-3 weeks for a response.
          </p>

          <h3 className='text-xl font-semibold my-5'>
            Approved organizations may hyperlink to our Website as follows:
          </h3>

          <p className='my-3'>
            By use of our corporate name; or By use of the uniform resource
            locator being linked to; or By use of any other description of our
            Website being linked to that makes sense within the context and
            format of content on the linking party’s site. No use of
            Tickets4Travel Ltd.'s logo or other artwork will be allowed for
            linking absent a trademark license agreement.
          </p>

          <h3 className='text-xl font-semibold my-5'>iFrames</h3>

          <p className='my-3'>
            Without prior approval and written permission, you may not create
            frames around our Webpages that alter in any way the visual
            presentation or appearance of our Website.
          </p>

          <h3 className='text-xl font-semibold my-5'>Content Liability</h3>

          <p className='my-3'>
            We shall not be hold responsible for any content that appears on
            your Website. You agree to protect and defend us against all claims
            that is rising on your Website. No link(s) should appear on any
            Website that may be interpreted as libelous, obscene or criminal, or
            which infringes, otherwise violates, or advocates the infringement
            or other violation of, any third party rights.
          </p>

          <h3 className='text-xl font-semibold my-5'>Your Privacy</h3>

          <p className='my-3'>
            Please read{' '}
            <Link to='/privacy-policy' className='underline'>
              Privacy Policy
            </Link>
          </p>

          <h3 className='text-xl font-semibold my-5'>Reservation of Rights</h3>

          <p className='my-3'>
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request. We also reserve the right to
            amen these terms and conditions and it’s linking policy at any time.
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions. Removal of links from our
            website
          </p>

          <p className='my-3'>
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment. We will
            consider requests to remove links but we are not obligated to or so
            or to respond to you directly.
          </p>

          <p className='my-3'>
            We do not ensure that the information on this website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the website remains available or that the material on
            the website is kept up to date. Disclaimer
          </p>

          <p className='my-3'>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to our website
            and the use of this website. Nothing in this disclaimer will:
          </p>

          <p className='my-3'>
            limit or exclude our or your liability for death or personal injury;
            limit or exclude our or your liability for fraud or fraudulent
            misrepresentation; limit any of our or your liabilities in any way
            that is not permitted under applicable law; or exclude any of our or
            your liabilities that may not be excluded under applicable law. The
            limitations and prohibitions of liability set in this Section and
            elsewhere in this disclaimer: (a) are subject to the preceding
            paragraph; and (b) govern all liabilities arising under the
            disclaimer, including liabilities arising in contract, in tort and
            for breach of statutory duty.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageTermsConditions;
