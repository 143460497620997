import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import { useAppDispatch } from 'app/hook';
import { fetchAllTourAsync } from 'app/feature/tour/tourSlice';
import TourSectionGridFilterCard from './TourSectionGridFilterCard';

const PageTourSearch = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch<any>(fetchAllTourAsync());
  }, []);

  return (
    <div
      className={`nc-PageTourSearch relative overflow-hidden`}
      data-nc-id='PageTourSearch'
    >
      <Helmet>
        <title>TicketsForTravel | Book Hotel From Anywhere</title>
      </Helmet>
      <BgGlassmorphism />

      <div className='container relative overflow-hidden'>
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage='Package Tour'
          currentTab='Package Tour'
          className='pt-10 pb-24 lg:pb-20 lg:pt-20'
        />

        <TourSectionGridFilterCard className='pb-24 lg:pb-32' />

        <SectionSubscribe2 className='py-24 lg:py-32' />
      </div>
    </div>
  );
};

export default PageTourSearch;
