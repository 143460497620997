import { FC, Fragment, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { fetchOffers } from 'app/feature/booking/bookingApi';
import PageLoading from 'containers/PageLoading/PageLoading';
import NcImage from 'shared/NcImage/NcImage';

export interface PageOfferProps {
  className?: string;
  uniqueID?: string;
}

const PageOffer: FC<PageOfferProps> = ({ className = '', uniqueID }) => {
  const [offer, setOffer] = useState<{ _id: string; img: string }[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchOfferAsync = async () => {
    const resposne = await fetchOffers();
    setOffer(resposne.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchOfferAsync();
  }, []);

  return (
    <div className={`nc-PageOffer ${className}`} data-nc-id='PageLogin'>
      <Helmet>
        <title>Offer | TicketsForTravel</title>
      </Helmet>

      <div className='container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row'>
        {loading ? (
          <PageLoading />
        ) : (
          <Fragment>
            {offer && offer.length !== 0 ? (
              <div className='grid gap-4 grid-flow-col grid-cols-3'>
                {offer.map((item) => (
                  <div key={item._id}>
                    <NcImage src={item.img} />
                  </div>
                ))}
              </div>
            ) : (
              <div>There isn't any offer right now</div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PageOffer;
