import { FC } from "react";

export interface BusSeatProps {
  className?: string;
  seat: {
    key: string;
    selected: boolean;
  };
  threeSitter: boolean;
  selectedSeat: string[];
  handleSeatSelect: (ev: any) => void;
}

const BusSeat: FC<BusSeatProps> = ({
  className = "",
  seat,
  threeSitter = false,
  selectedSeat = [],
  handleSeatSelect,
}) => {
  return (
    <div className={`nc-BusSeat ${className}`} data-nc-id="BusSeat">
      <li
        className={`nc-BusSeat p-1 relative justify-center my-1
        ${threeSitter
            ? seat.key.slice(-1) === "2" && "ml-12"
            : (seat.key.slice(-1) === "2" && "mr-5") ||
            (seat.key.slice(-1) === "3" && "ml-5")
          }`}
        key={seat.key}
      >
        <input
          type="checkbox"
          disabled={seat.selected}
          id={seat.key}
          value={seat.key}
          onClick={handleSeatSelect}
        />
        <label
          className={`${selectedSeat.length > 4 ? "bg-gray-200" : true}`}
          htmlFor={seat.key}
        >
          {seat.selected ? "X" : seat.key}
        </label>
      </li>
    </div>
  );
};

export default BusSeat;
