import { Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { fetchNotify } from 'app/feature/booking/bookingApi';
import { useAppSelector } from 'app/hook';
import {
  Notification,
  NotifyApiCall,
} from 'app/feature/booking/bookingInterface';
import Avatar from 'shared/Avatar/Avatar';
import moment from 'moment';

export default function NotifyDropdown() {
  const { token } = useAppSelector((state) => state.user);
  const [nofications, setNofications] = useState<Notification[]>([]);

  useEffect(() => {
    fetchNotification();
  }, []);

  const fetchNotification = async () => {
    const { data }: NotifyApiCall = await fetchNotify(token);
    setNofications(data);
  };

  return (
    <div className=''>
      <Popover className='relative'>
        {({ open }) => (
          <Fragment>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'}
                 group  p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100
                  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9'
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <Popover.Panel className='absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 mt-3 -right-28 sm:right-0 sm:px-0'>
                <div className=' overflow-scroll rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 max-h-96'>
                  <div className='relative grid gap-8 bg-white dark:bg-neutral-800 p-7'>
                    <div className='flex justify-between items-baseline'>
                      <h3 className='text-xl font-semibold'>Notifications</h3>

                      {/* TODO: Click to delete all notifications */}
                      <span className=' text-sm text-gray-600 cursor-pointer hover:text-blue-600'>
                        Clear All
                      </span>
                    </div>

                    {nofications && nofications.length !== 0 ? (
                      nofications.map((item, index) => (
                        <div
                          key={index}
                          className='flex p-2 pr-8 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative'
                        >
                          <Avatar
                            imgUrl=''
                            userName={`${(index + 1).toString()}`}
                            sizeClass='w-8 h-8 sm:w-12 sm:h-12'
                          />
                          <div className='ml-3 sm:ml-4 space-y-1'>
                            <p className='text-sm font-medium text-gray-900 dark:text-gray-200'>
                              {item.title}
                            </p>
                            <p className='text-xs sm:text-sm text-gray-500 dark:text-gray-400'>
                              {item.body}
                            </p>
                            <p className='text-xs text-gray-400 dark:text-gray-400'>
                              {moment(item.createdAt).fromNow()}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className='flex justify-center'>
                        <h1>No Notifications</h1>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Fragment>
        )}
      </Popover>
    </div>
  );
}
