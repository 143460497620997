import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchBusCities,
  fetchBusList,
  fetchSingleBus,
  fetchVat,
} from './busApi';

import {
  CitiesApiCall,
  BusState,
  BusListApiCall,
  OneBusApiCall,
  VatAndServiceApiCall,
} from './busInterfaces';

const initialState: BusState = {
  status: 'idle',
  cities: [],
  busList: [],
  firstBusSelected: false,
  secendBusSelected: false,
};

export const fetchBusCitiesAsync = createAsyncThunk(
  'bus/fetchCities',
  async () => {
    const { data }: CitiesApiCall = await fetchBusCities();
    return data;
  }
);

export const fetchBusListAsync = createAsyncThunk(
  'bus/fetchBusList',
  async (userData: any) => {
    const { depDate, fromCityId, toCityId } = userData;
    const { data }: BusListApiCall = await fetchBusList(
      depDate,
      fromCityId,
      toCityId
    );
    return data;
  }
);

export const fetchSingleBuslAsync = createAsyncThunk(
  'bus/fetchSingleBus',
  async (userChosenData: any) => {
    const { id, depDate, fromLocId, toLocId } = userChosenData;
    const { data }: OneBusApiCall = await fetchSingleBus(
      id,
      depDate,
      fromLocId,
      toLocId
    );
    return data;
  }
);

export const fetchVatAsync = createAsyncThunk('bus/fetchVat', async () => {
  const { data }: VatAndServiceApiCall = await fetchVat();
  return data;
});

export const busSlice = createSlice({
  name: 'bus',
  initialState,
  reducers: {
    addUserInput: (state, action) => {
      state.busUserInput = action.payload;
    },
    addFinalInput: (state, action) => {
      state.busFinalInput = action.payload;
    },
    addInputFirstBus: (state, action) => {
      state.firstBusSelected = true;
      state.inputFirstBus = action.payload;
    },
    addInputSecendBus: (state, action) => {
      state.secendBusSelected = true;
      state.inputSecendBus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusCitiesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBusCitiesAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.cities = action.payload;
      })
      .addCase(fetchBusCitiesAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchBusListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBusListAsync.fulfilled, (state, action) => {
        state.status = 'loading';
        state.busList = action.payload.bus;
      })
      .addCase(fetchBusListAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSingleBuslAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSingleBuslAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.oneBus = action.payload;
      })
      .addCase(fetchSingleBuslAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchVatAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVatAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.vatAndServices = action.payload;
      })
      .addCase(fetchVatAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {
  addUserInput,
  addFinalInput,
  addInputFirstBus,
  addInputSecendBus,
} = busSlice.actions;

export default busSlice.reducer;
