import { FC } from 'react';
import moment from 'moment';
// import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
import Heading2 from 'components/Heading/Heading2';
import CarCard from 'components/CarCard/CarCard';
import { useAppSelector } from 'app/hook';

export interface SectionGridFilterCardProps {
  className?: string;
}

// const DEMO_DATA: CarDataType[] = DEMO_CAR_LISTINGS.filter((_, i) => i < 12);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = '',
}) => {
  const { carUserInput, cars } = useAppSelector((state) => state.car);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id='SectionGridFilterCard'
    >
      <Heading2
        heading={`Cars avaiable for ${carUserInput?.fromRegion}`}
        subHeading={
          <span className='block text-neutral-500 dark:text-neutral-400 mt-3'>
            {cars.length} cars
            <span className='mx-2'>·</span>
            {moment(carUserInput?.depDate).format('MMM DD')} -{' '}
            {moment(carUserInput?.retDate).format('DD')}
          </span>
        }
      />

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
        {cars.map((car) => (
          <CarCard key={car._id} data={car} />
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
