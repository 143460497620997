import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DateRage } from 'data/types';
import useWindowSize from 'hooks/useWindowResize';
import moment from 'moment';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useAppSelector, useAppDispatch } from 'app/hook';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import RentalCarDatesRangeInput from 'components/HeroSearchForm/RentalCarDatesRangeInput';
import { TimeRage } from 'components/HeroSearchForm/RentalCarSearchForm';
import { fetchSingleCarAsync, addFinalInput } from 'app/feature/car/carSlice';
import SomethingWrong from 'containers/PageSomethingWrong/PageSomethingWrong';
import Page404 from 'containers/Page404/Page404';
import PageLoading from 'containers/PageLoading/PageLoading';

export interface PageCarDetailsProps {
  match?: any;
}

const PageCarDetails: FC<PageCarDetailsProps> = ({ match }) => {
  const { carUserInput, oneCar, status } = useAppSelector((state) => state.car);

  const { isLogged } = useAppSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useAppDispatch();

  // USE STATE
  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: carUserInput?.depDate
      ? moment(carUserInput.depDate)
      : moment().add(1, 'days'),
    endDate: carUserInput?.retDate
      ? moment(carUserInput.retDate)
      : moment().add(3, 'days'),
  });
  const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    startTime: carUserInput?.depTime ? carUserInput.depTime : '10:00 AM',
    endTime: '10:00 AM',
  });
  let day = 2;

  const { id } = match.params;

  useEffect(() => {
    dispatch<any>(fetchSingleCarAsync({ id }));
  }, [id]);

  const windowSize = useWindowSize();

  if (dateRangeValue.endDate) {
    day = dateRangeValue.endDate.diff(dateRangeValue.startDate, 'days') + 1;
  }

  const renderCarDetails = () => {
    return (
      <div className='listingSection__wrap !space-y-6'>
        {/* 1 */}
        <div className='flex items-center'>
          {oneCar?.AC && <Badge className='mr-2' color='blue' name='AC' />}
          <Badge color='gray' name={oneCar?.carCondition} />
        </div>

        {/* 2 */}
        <h2 className='text-2xl sm:text-3xl lg:text-4xl font-semibold'>
          {oneCar?.vehicleName}
        </h2>

        <span className='text-neutral-500 dark:text-neutral-400'>
          Model:{' '}
          <span className='text-neutral-900 dark:text-neutral-200 font-medium'>
            {oneCar?.model}
          </span>
        </span>

        {/* 3 */}

        <span className='text-neutral-500 dark:text-neutral-400'>
          Agency:{' '}
          <span className='text-neutral-900 dark:text-neutral-200 font-medium'>
            {oneCar?.agentName}
          </span>
        </span>

        {/* 4 */}
        <div className='w-full border-b border-neutral-100 dark:border-neutral-700' />

        {/* 5 */}
        <div className='flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300'>
          <div className='flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 '>
            <i className='las la-user-friends text-2xl' />
            <span className=''>{oneCar?.passengerCapacity} seats</span>
          </div>

          <div className='flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 '>
            <i className='las la-door-open text-2xl' />
            <span className=''> {oneCar?.numOfDoors} doors</span>
          </div>

          <div className='flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 '>
            <i className='las la-gas-pump text-2xl' />
            <span className=''> {oneCar?.fuelType}</span>
          </div>
          <div className='flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 '>
            <i className='las la-suitcase text-2xl' />
            <span className=''> {oneCar?.luggageCapacity} bag</span>
          </div>
        </div>
      </div>
    );
  };

  const renderTerms = () => {
    return (
      <div className='listingSection__wrap !space-y-6'>
        <h2 className='text-2xl font-semibold'>Things to know</h2>
        <div className='w-14 border-b border-neutral-200 dark:border-neutral-700' />

        <div>
          <h4 className='text-lg font-semibold'>Terms And Conditions</h4>
          <span className='block mt-3 text-neutral-500 dark:text-neutral-400'>
            {oneCar?.termsAndConditions}
          </span>
        </div>
      </div>
    );
  };

  const reserveBtnClick = () => {
    if (oneCar) {
      dispatch(
        addFinalInput({
          car: oneCar,
          amount: oneCar.dailyRent * day,
          depTime: timeRangeValue.startTime,
          depDate: dateRangeValue.startDate,
          retDate: dateRangeValue.endDate,
          day: day,
        })
      );
    }

    if (dateRangeValue.startDate !== null && dateRangeValue.endDate !== null) {
      if (isLogged) {
        history.push('/rent-a-car/checkout');
      } else {
        history.push('/login');
      }
    }
  };

  const renderSidebarPrice = () => {
    return (
      oneCar && (
        <div className='listingSection__wrap shadow-xl'>
          <span className='font-semibold text-2xl tracking-wide'>
            {oneCar.vehicleName}
          </span>

          <div className='flex justify-between'>
            <span className='text-3xl font-semibold'>
              BDT {oneCar.dailyRent}
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /days
              </span>
            </span>
          </div>

          {/* FORM */}
          <form className='flex border  border-neutral-200 dark:border-neutral-700 rounded-3xl '>
            <RentalCarDatesRangeInput
              defaultDateValue={dateRangeValue}
              defaultTimeValue={timeRangeValue}
              numberOfMonths={1}
              fieldClassName='p-5'
              wrapFieldClassName='flex flex-col w-full flex-shrink-0 relative divide-y divide-neutral-200 dark:divide-neutral-700'
              onChange={(data) => {
                setDateRangeValue(data.stateDate);
                setTimeRangeValue(data.stateTimeRage);
              }}
              anchorDirection={windowSize.width > 1400 ? 'left' : 'right'}
            />
          </form>

          {/* SUM */}
          <div className='flex flex-col space-y-4 '>
            <div className='flex justify-between text-neutral-6000 dark:text-neutral-300'>
              <span>
                {oneCar.dailyRent} x {day} days
              </span>
              <span>BDT {oneCar.dailyRent * day}</span>
            </div>

            <div className='border-b border-neutral-200 dark:border-neutral-700'></div>
            <div className='flex justify-between font-semibold'>
              <span>Total</span>
              <span>BDT {oneCar.dailyRent * day}</span>
            </div>
          </div>

          {/* SUBMIT */}
          <ButtonPrimary onClick={reserveBtnClick}>Reserve</ButtonPrimary>
        </div>
      )
    );
  };

  return status === 'loading' ? (
    <PageLoading />
  ) : status === 'idle' ? (
    oneCar ? (
      <div className={`nc-PageCarDetaila`} data-nc-id='PageCarDetails'>
        {/* MAIn */}
        <main className='container mt-11 flex '>
          {/* CONTENT */}
          <div className='w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10'>
            {renderCarDetails()}
            {renderTerms()}
          </div>

          {/* SIDEBAR */}
          <div className='hidden lg:block flex-grow'>
            <div className='sticky top-24'>{renderSidebarPrice()}</div>
          </div>
        </main>

        {/* STICKY FOOTER MOBILE */}
        <div className='block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20'>
          <div className='container flex items-center justify-between'>
            <span className='text-2xl font-semibold'>
              BDT {oneCar.dailyRent}
              <span className='ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400'>
                /day
              </span>
            </span>

            <ButtonPrimary onClick={reserveBtnClick}>Reserve</ButtonPrimary>
          </div>
        </div>

        {/* OTHER SECTION */}
        <div className='container py-24 lg:py-32'>
          {/* SECTION 1 */}
          {/* <div className='relative py-16'>
            <BackgroundSection />
            <SectionSliderNewCategories
              heading='Explore by types of stays'
              subHeading='Explore houses based on 10 types of stays'
              categoryCardType='card5'
              itemPerRow={5}
              sliderStyle='style2'
            />
          </div> */}

          {/* SECTION */}
          <SectionSubscribe2 className='pt-24 lg:pt-32' />
        </div>
      </div>
    ) : (
      <SomethingWrong />
    )
  ) : (
    <Page404 />
  );
};

export default PageCarDetails;
