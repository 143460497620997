import { FC } from 'react';
import { Link } from 'react-scroll';
import imagePng from 'images/hero-right.png';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import HeroSearchForm from 'components/HeroSearchForm/HeroSearchForm';

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col relative ${className}`}
      data-nc-id='SectionHero'
    >
      <div className='z-10 w-full flex justify-center'>
        <HeroSearchForm />
      </div>

      <div className='flex flex-col mt-20 lg:mt-0 lg:flex-row lg:items-center'>
        <div className='flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0 lg:mt-48'>
          <div>
            <h2 className='font-medium text-4xl md:text-5xl xl:text-5xl leading-[110%]'>
              Your Convenient
            </h2>
            <h2 className='font-medium text-4xl md:text-5xl xl:text-6xl leading-[110%] mb-5'>
              Travel Assistant
            </h2>

            <span className='text-base md:text-lg text-neutral-500 dark:text-neutral-400'>
              One-stop solution for all you need to plan a trip, reserve all the
              remarkable travel services and experience the best of them!
            </span>
          </div>

          <ButtonPrimary>
            <Link
              to='download-app'
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Download Our App
            </Link>
          </ButtonPrimary>
        </div>
        <div className='flex-grow moveUpDown'>
          <img className='w-full' src={imagePng} alt='hero' />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;

// 02, 60, d7
