import rightImg from 'images/about-hero-right.png';
import rightImg2 from 'images/mission-hero-left.png';
import Helmet from 'react-helmet';
import SectionHero from './SectionHero';

const PageAboutUs = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id='PageAbout'
    >
      <Helmet>
        <title>About Us | TicketsForTravel</title>
      </Helmet>

      <div className='container py-16 lg:py-28 space-y-16 lg:space-y-28'>
        <SectionHero
          rightImg={rightImg}
          heading='About Us'
          btnText=''
          subHeading='As the world gets smaller every day, connecting with the other edge of the planet gets easier, faster, and more convenient. TicketsForTravel acknowledges the impact of global modernization and seeks to serve anyone in need of “being” somewhere, and with help of the latest methodologies, anywhere at ease.
          Thus began the voyage to provide a better platform for a world that is gradually getting inclined more and more towards traveling tourism.'
        />

        <SectionHero
          rightImg={rightImg2}
          heading='Mission'
          btnText=''
          className='lg:flex-row-reverse'
          subHeading="TicketsForTravel associates in booking services discrete yet interconnected in nature relating  <strong>Travels</strong> such as
          <li style='box-sizing: inherit; margin-bottom: 0px; margin-left: 18px; margin-top: 10px;'><strong>E-Ticketing</strong> to transport services of all available public routes</li>
          <li style='box-sizing: inherit; margin-bottom: 0px; margin-left: 18px;'><strong>Booking</strong> leisure residence and additional commodities</li>
          <li style='box-sizing: inherit; margin-bottom: 0px; margin-left: 18px; margin-bottom: 10px;'>Associating <strong>Package Tours</strong> to the popular tourist spots</li>
          Still, we aspire to enhance more and more as we grow contemplating to bring serenity and luxury in transport and tourism that everyone deserves and looks for once they step out to have a better time."
        />
      </div>
    </div>
  );
};

export default PageAboutUs;
