import { FC } from 'react';
import { Link } from 'react-router-dom';
import logoLightImg from 'images/blue-logo.png';

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: FC<LogoProps> = ({ img = logoLightImg, className = '' }) => {
  return (
    <Link
      to='/'
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      <img className={`block max-h-12`} src={img} alt='Logo' />
    </Link>
  );
};

export default Logo;
