import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Label from 'components/Label/Label';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import CommonLayout from './CommonLayout';
import { fetchUpdateCurrentPassword } from 'app/feature/user/userApi';
import { useAppSelector } from 'app/hook';

const ChangePassword = () => {
  const { token } = useAppSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [passwordCurrent, setPasswordCurrent] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const history = useHistory();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (password !== passwordConfirm) {
      setError("Password Doesn't match");
      setLoading(false);
    }

    const data: any = await fetchUpdateCurrentPassword(token, {
      passwordCurrent,
      password,
      passwordConfirm,
    });
    setLoading(false);

    if (data.status === 'success') {
      history.push('/');
    } else {
      setError(data);
    }
  };

  return (
    <div>
      <CommonLayout>
        <form className='space-y-6 sm:space-y-8' onSubmit={handleSubmit}>
          {/* HEADING */}
          <h2 className='text-3xl font-semibold'>Update your password</h2>
          <div className='w-14 border-b border-neutral-200 dark:border-neutral-700'></div>
          <div className=' max-w-xl space-y-6'>
            <div>
              <Label>Current password</Label>
              <Input
                type='password'
                className='mt-1.5'
                value={passwordCurrent}
                onChange={(ev) => setPasswordCurrent(ev.target.value)}
              />
            </div>

            <div>
              <Label>New password</Label>
              <Input
                type='password'
                className='mt-1.5'
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
              />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input
                type='password'
                className='mt-1.5'
                value={passwordConfirm}
                onChange={(ev) => setPasswordConfirm(ev.target.value)}
              />
            </div>
            <div className='pt-2'>
              <ButtonPrimary loading={loading}>Update password</ButtonPrimary>
            </div>

            <div className='pt-2'>
              {error && (
                <span className='text-red-400 dark:text-red-400'>{error}</span>
              )}
            </div>
          </div>
        </form>
      </CommonLayout>
    </div>
  );
};

export default ChangePassword;
