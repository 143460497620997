import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import PageHome from 'containers/PageHome/PageHome';
import Header from 'shared/Header/Header';
import Page404 from 'containers/Page404/Page404';
import UserPage from 'containers/PageUser/PageUser';
import EditProfile from 'containers/PageEditProfile/EditProfile';
import ChangePassword from 'containers/PageEditProfile/ChangePassword';
import PageContactUs from 'containers/PageContact/PageContactUs';
import PageAboutUs from 'containers/PageAboutUs/PageAbout';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageLogin from 'containers/PageLogin/PageLogin';
import PageHotelCheckOut from 'containers/PageHotelCheckout/PaegHotelCheckout';
import PageHotelSearch from 'containers/PageHotelSearch/PageHotelSearch';
import PageHotelDetails from 'containers/PageHotelDetails/PageHotelDetails';
import PageHotelPayDone from 'containers/PageHotelPayDone/PageHotelPayDone';
import PageBusSearch from 'containers/PageBusSearch/PageBusSearch';
import PageBusDetails from 'containers/PageBusDetails/PageBusDetails';
import PageBusCheckOut from 'containers/PageBusCheckout/PageBusCheckout';
import PageBusRoundtripCheckout from 'containers/PageBusRoundtripCheckout/PageBusRoundtripCheckout';
import PageLaunchSearch from 'containers/PageLaunchSearch/PageLaunchSearch';
import PagePartnership from 'containers/PagePartnership/PagePartnership';
import PagePrivacyPolicy from 'containers/PagePrivacyPolicy/PagePrivacyPolicy';
import PageTermsConditions from 'containers/PageTermsConditions/PageTermsConditions';
import PageRefundPolicy from 'containers/PageRefundPolicy/PageRefundPolicy';
import PageCarSearch from 'containers/PageCarSearch/PageCarSearch';
import PageCarDetails from 'containers/PageCarDetails/PageCarDetails';
import PageCarCheckOut from 'containers/PageCarCheckout/PageCarCheckout';
import PageTourSearch from 'containers/PageTourSearch/PageTourSearch';
import PageTourDetails from 'containers/PageTourDetails/PageTourDetails';
import PageTourCheckOut from 'containers/PageTourCheckout/PageTourCheckout';
import PageBusPayDone from 'containers/PageBusPayDone/PageBusPayDone';
import PageLaunchDetails from 'containers/PageLaunchDetails/PageLaunchDetails';
import PageLaunchCheckOut from 'containers/PageLaunchCheckout/PageLaunchCheckout';
import PageLaunchPayDone from 'containers/PageLaunchPayDone/PageLaunchPayDone';
import PageEmailConfirm from 'containers/PageUserConfirm/PageUserConfirm';
import PageResetPassword from 'containers/PageVerification/PageVerification';
import PageSearchAccount from 'containers/PageSearchAccount/PageSearchAccount';
import PageOffer from 'containers/PageOffer/PageOffer';
import PageVerification from 'containers/PageVerification/PageVerification';

export const pages: Page[] = [
  { path: '/', exact: true, component: PageHome },
  { path: '/#', exact: true, component: PageHome },
  //
  { path: '/hotel', exact: true, component: PageHotelSearch },
  { path: '/hotel/checkout', exact: true, component: PageHotelCheckOut },
  { path: '/hotel/:id', exact: true, component: PageHotelDetails },
  { path: '/hotel-pay-done', exact: true, component: PageHotelPayDone },
  //
  { path: '/bus', exact: true, component: PageBusSearch },
  { path: '/bus/checkout', exact: true, component: PageBusCheckOut },
  {
    path: '/bus/roundtrip-checkout',
    exact: true,
    component: PageBusRoundtripCheckout,
  },
  { path: '/bus/:id', exact: true, component: PageBusDetails },
  { path: '/bus-pay-done', component: PageBusPayDone },
  { path: '/bus-return-pay-done', component: PageBusPayDone },
  //
  { path: '/launch', exact: true, component: PageLaunchSearch },
  { path: '/launch/checkout', exact: true, component: PageLaunchCheckOut },
  { path: '/launch/roundtrip-checkout', exact: true, component: PageHome },
  { path: '/launch/:id', exact: true, component: PageLaunchDetails },
  { path: '/launch-pay-done', exact: true, component: PageLaunchPayDone },
  {
    path: '/launch-return-pay-done',
    exact: true,
    component: PageLaunchPayDone,
  },
  //
  { path: '/rent-a-car', exact: true, component: PageCarSearch },
  { path: '/rent-a-car/checkout', exact: true, component: PageCarCheckOut },
  { path: '/rent-a-car/:id', exact: true, component: PageCarDetails },
  { path: '/car-pay-done', exact: true, component: PageHotelPayDone },
  //
  { path: '/package-tour', exact: true, component: PageTourSearch },
  { path: '/package-tour/checkout', exact: true, component: PageTourCheckOut },
  { path: '/package-tour/:id', exact: true, component: PageTourDetails },
  //
  { path: '/signup', component: PageSignUp },
  { path: '/login', component: PageLogin },
  { path: '/verification', component: PageVerification },
  { path: '/confirm-user/:token', component: PageEmailConfirm },
  { path: '/reset-password/:token', component: PageResetPassword },
  { path: '/search-account', component: PageSearchAccount },
  //
  { path: '/user', component: UserPage },
  { path: '/edit-profile', component: EditProfile },
  { path: '/change-password', component: ChangePassword },
  //
  { path: '/partnership-with-us', component: PagePartnership },
  { path: '/about-us', component: PageAboutUs },
  { path: '/offers', component: PageOffer },
  { path: '/contact-us', component: PageContactUs },
  { path: '/privacy-policy', component: PagePrivacyPolicy },
  { path: '/refund-policy', component: PageRefundPolicy },
  { path: '/terms-and-conditions', component: PageTermsConditions },
  //
  { path: '/page404', component: Page404 },
];

const Routes = () => {
  return (
    <BrowserRouter basename='/'>
      <ScrollToTop />
      <Header />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
