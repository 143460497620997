import { FC } from 'react';
import TourTabFilters from './TourTabFilters';
import Heading2 from 'components/Heading/Heading2';
import { useAppSelector } from 'app/hook';
import TourCard from 'components/TourCard/TourCard';

export interface TourSectionGridFilterCardProps {
  className?: string;
}

const TourSectionGridFilterCard: FC<TourSectionGridFilterCardProps> = ({
  className = '',
}) => {
  const { tourUserInput, tours } = useAppSelector((state) => state.tour);

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id='SectionGridFilterCard'
    >
      <Heading2
        heading={
          tourUserInput
            ? `All Tours in ${tourUserInput.location.cityName}`
            : 'All Tours'
        }
        subHeading={
          <span className='block text-neutral-500 dark:text-neutral-400 mt-3'>
            Total {tours.length} tours
          </span>
        }
      />

      <div className='mb-8 lg:mb-11'>
        <TourTabFilters />
      </div>

      <div className='grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4'>
        {tours.map((tour) => (
          <TourCard key={tour.id} tour={tour} />
        ))}
      </div>
      {/* <div className='flex mt-16 justify-center items-center'>
        <Pagination />
      </div> */}
    </div>
  );
};

export default TourSectionGridFilterCard;
