import axios from 'axios';

const URL = process.env.REACT_APP_SERVER_URL;

export const fetchSignUp = async (body: any) => {
  try {
    const response = await axios.post(`${URL}api/v1/users/signup`, body);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchLogin = async (body: any) => {
  try {
    const response = await axios.post(`${URL}api/v1/users/login`, body);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchVerifyEmail = async (token: string) => {
  try {
    const response = await axios.patch(
      `${URL}api/v1/users/verify-email/${token}`
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchConfirmUser = async (body: any) => {
  try {
    const response = await axios.patch(`${URL}api/v1/users/confirmUser`, body);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchLogout = async () => {
  try {
    const response = await axios.get(`${URL}api/v1/users/logout`);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchIdentity = async (body: any) => {
  try {
    const response = await axios.patch(
      `${URL}api/v1/users/forgotPassword`,
      body
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchCheckResetOTP = async (body: any) => {
  try {
    const response = await axios.post(
      `${URL}api/v1/users/check-reset-otp`,
      body
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchResetPassword = async (
  token: string,
  body: { password: string; passwordConfirm: string }
) => {
  try {
    const response = await axios.patch(
      `${URL}api/v1/users/resetPassword/${token}`,
      body
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchUpdateUser = async (token: string, body: any) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.patch(
      `${URL}api/v1/users/updateMe`,
      body,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};

export const fetchUpdateCurrentPassword = async (
  token: string,
  body: {
    passwordCurrent: string;
    password: string;
    passwordConfirm: string;
  }
) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await axios.patch(
      `${URL}api/v1/users/updateMyPassword`,
      body,
      config
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.data.message;
    } else if (error.request) {
      return error.request;
    } else {
      return error.message;
    }
  }
};
